export default {
  add: 'hozzáadása',
  addNew: {
    title: 'Felvitel'
  },
  addressInfos: 'Cím adatok',
  addressAndPaymentInfos: 'Cím és fizetési adatok',
  addressBilling: 'Számlázási cím',
  addressShipIn: 'Begyűjtési cím',
  addressShipOut: 'Kiszállítási cím',
  addressType: 'Cím típus',
  ADMINGrading: 'Admin minősítés',
  adminCalculatedPrice: 'Végleges ajánlat',
  adminLogin: 'Ügyintézői bejelentkezés',
  AGENTGrading: 'Agent minősítés',
  alert: 'Figyelem',
  availableDevices: 'Elérhető készülékek',
  availableService: 'Elérhető szolgáltatás',
  availableServices: 'Elérhető szolgáltatások',
  backAccountHelper: 'Használt készülék adás-vétel feltétele a helyes bankszámla megadása!<br>'
    + 'Amennyiben a bankszámlaszám 16 számjegyből áll, a 17-24-ig terjedő számjegyeket 0-val egészítsd ki kérlek.',
  basicData: 'Alapadatok',
  billingInformation: 'Számlázási adatok',
  brandAndProductTypeSelector: 'Gyártó és típus választó',
  brandTranslation: 'Gyártó fordítás',
  businessForm: 'Cégforma',
  buybackDevice: 'Beszámíttatni kívánt készülék',
  buybackPrice: 'Az eszköz vételi ára',
  buybackPreOfferResendTitle: 'Előzetes ajánlat újraküldése',
  buybackPreOfferResendQuestion: 'Biztos, hogy újra kiküldi az előzetes árajánlat levelet?',
  buybackFinalOfferResendTitle: 'Végleges ajánlat újraküldése',
  buybackFinalOfferResendQuestion: 'Biztos, hogy újra kiküldi a végleges árajánlat levelet?',
  calculatedPrice: 'Indikatív ajánlat',
  calculationHistory: 'Kalkuláció napló',
  calculatorHelper: 'Vizsgáld meg a készüléket!',
  contactType: 'Kapcsolat felvétel típus',
  contractApply: 'Szerződés véglegesítése',
  contractNextHelper: 'Mi történt a szerződéssel?',
  contractInfos: 'Szerződéshez szükséges információk',
  crmService: 'CRM Szolgáltatás',
  crmUser: 'CRM felhasználó',
  currency: 'Pénznem',
  customDateRange: 'Egyedi intervallum',
  customerInfos: 'Ügyfél adatok',
  customerType: 'Ügyfél típus',
  date: 'Dátum',
  deleteContract: 'Szerződés törlése',
  deliveryAddress: 'Elszállítási cím',
  deliveryInAddress: 'Átvételi cím',
  deliveryOutAddress: 'Átadási cím',
  deviceInfos: 'Készülék adatok',
  deviceStatus: 'Készülék állapota',
  deviceToBuy: 'Megvásárolni kívánt készülék',
  deviceToSell: 'Beszámíttatni kívánt készülék',
  document: 'Dokumentum | Dokumentumok',
  documentDetails: 'Dokumentum adatok',
  documentStatusHistory: 'Dokumentum státusz történet',
  documentValidation: 'Dokumentumok kezelése',
  doneHelper: 'Kérlek a nyomtatást követően kettő példányban írasd alá a szerződést az Ügyféllel, '
    + 'majd egy példányt adj át neki, a másik példányt pedig tedd a készülék mellé.',
  downloadContract: 'Szerződés letöltése',
  downloadAndPrintContract: 'Kérlek töltsd le és két példányban nyomtasd ki a szerződést!',
  driverLicense: 'Jogosítvány',
  addTranslation: 'Új fordítás hozzáadása',
  editTranslation: 'Fordítás szerkesztése',
  email: 'E-mail',
  errorCode: 'Hibakód',
  errorMessage: 'Hibaüzenet',
  expertReport: 'Szakvélemény',
  expTime: 'Lejárati idő',
  finalOfferAcceptHelpText: 'A lenti <b>Végleges ajánlat</b> elfogadása gombra kattintva elfogadod az Adásvételi szerződés '
    + 'feltételeit, melyeknek megfelelően a TMX Mobile Solution Kft. 2 munkanapon belül az általad megadott '
    + 'bankszámlaszámra átutalja a készülék végleges ajánlat szerinti vételárát.',
  finalOfferDownloadContractHelpText: 'Az <b>Adásvételi szerződés</b> letöltéséhez kattints az alábbi gombra:',
  finalOfferPrice: 'Végleges ajánlat',
  financialInfos: 'Pénzügyi adatok',
  firstStep: '1. lépés',
  forgotPassword: 'Elfelejtett jelszó',
  generalInfos: 'Általános információk',
  idCard: 'Személyi igazolvány',
  invoice: 'Számla',
  invoices: 'Számlák',
  isCompanyWarrantyAlert: 'Az Apple korlátozott jótállása nem fogyasztók részére a készülék első aktiválástól '
    + 'számított 1 évig tart és nem indul újra!',
  issuedDevices: 'Kiadott készülékek',
  lastDaysSubmittedRequests: 'Utolsó {count} napban leadott igények',
  lineChart: 'Vonal diagram',
  loanDeviceDetails: 'Kölcsönkészülék adatai',
  logins: 'Bejelentkezések',
  mobilProvider: 'Mobil szolgáltató',
  mySavedSearches: 'Mentett kereséseim',
  namePrefix: 'Név előtag',
  networkLock: 'Hálózati zár',
  new: 'Új',
  noRoleToVisitPage: 'A keresett oldal megtekintéséhez nincs megfelelő jogosultságod!',
  noRoleToVisitPageTodo: 'További információkért fordulj XYhoz vagy írj az xy@xy.xy email címre.',
  notSearchable: 'Nem kereshető',
  orderAndVisibility: 'Sorrend és láthatóság',
  orderDisclaimer: 'Megadott adataid alapján elküldjük a készüléked indikatív felvásárlási árát, a beállított '
    + 'kondíciók alapján. Amennyiben a kapott ajánlatot szimpatikusnak találod, futárunk elmegy a készülékért, '
    + 'hogy technikus szakembereink megvizsgálhassák azt, majd végleges ajánlatot adjanak, mely elfogadása esetén '
    + 'utaljuk a vételárat, elutasítás esetén pedig visszaküldjük az eszközt.',
  ooops: 'Hiba',
  passport: 'Útlevél',
  paymentMethod: 'Fizetési mód',
  paymentMethods: 'Fizetési módok',
  pickupStore: 'Leadási pont',
  preDefinedDateRange: 'Előre definiált intervallum',
  preorder: 'Preorder',
  product: 'Termék',
  quickSearch: 'Gyorskeresés',
  quote: 'Árajánlat',
  rangeSelect: 'Időintervallum megadása',
  repairInfo: 'Javítási adat',
  report: 'Jegyzőkönyv',
  requestId: 'Igény azonosító',
  row: 'sor',
  sample: 'Minta',
  searchBasedOnImei: 'Keresés IMEI azonosító alapján',
  secondStep: '2. lépés',
  sendUsAMessage: 'Küldjön nekünk üzenetet!',
  ourEmail: 'E-mail címünk',
  ourPhoneNumber: 'Telefonszámunk',
  services: 'Szolgáltatások',
  shippingDisclaimer: 'Szállítási adataid alapján MPL futárunk előre egyeztetett időpontban elmegy a készülékedért, '
    + 'hogy elszállítást követően technikusunk felül tudja vizsgálni a kondíciós adatait. Ezután küldeni fogunk '
    + 'egy végleges ajánlatot, mely elfogadása esetén utaljuk a vételárat, elutasítás esetén pedig visszaküldjük '
    + 'az eszközt!',
  subtitle: 'Alcím',
  searchHelper: 'Amennyiben a fenti mezőben helyesen adtad meg készüléked IMEI számát és a <b>Keresés</b> '
    + 'gombra nyomtál, meg fog jelenni a készülék, amelyet be szeretnél számíttatni. Amennyiben a rendszer '
    + 'nem találja a megadott IMEI számot, az adott készüléket nincs módunkban megvásárolni.',
  searchInProgress: 'Keresés folyamatban',
  searchFoundDevice: 'A megadott IMEI azonosítóhoz a következő készülék tartozik:',
  searchNotFoundDevice: 'A megadott IMEI azonosítóhoz az adatbázisunkban nem tartozik készülék, ezért Egyéb '
    + 'kategóriába tettük. A folyamatot tudod folytatni!',
  selectedLangiage: 'Választott nyelv',
  shippingInformation: 'Szállítási adatok',
  statuses: 'Státuszok',
  statusHistory: 'Státusz napló',
  systemMessage: 'Rendszerüzenet | Rendszerüzenetek',
  tableHeaderOrderAndVisibility: 'Táblázat fejlécek sorrendje és láthatósága',
  thirdStep: '3. lépés',
  title: 'Cím',
  today: 'Mai dátum',
  transactionData: 'Fuvarlevél adatok',
  translation: 'Fordítás | Fordítások',
  uploadedInvoice: 'Feltöltött számla',
  user: 'Felhasználó',
  version: 'Verzió',
  visibility: 'Láthatóság',
  warrantyTicket: 'Garancia jegy',
  warrantyType: 'Garancia típus',
  waybillResult: 'fuvarlevél eredmény',
  working: 'Dolgozunk',
  yourDevice: 'Az Ön készüléke',
  yuppee: 'Sikeres művelet',
  button: {
    acceptDoc: 'Dokumentum elfogadása',
    acceptFinalOffer: 'Végleges ajánlat elfogadása',
    acceptPreOffer: 'Előzetes ajánlat elfogadása',
    addNew: 'Új hozzáadása',
    apply: 'Alkalmaz',
    back: 'Vissza',
    backToDashboard: 'Vissza a Vezérlőpultra',
    backToHome: 'Vissza a Kezdőlapra',
    backToLogin: 'Vissza a Bejelentkezéshez',
    baseFilter: 'Alaphelyzet',
    cancel: 'Mégsem',
    cargoCSVDownload: 'CSV fájl letöltése',
    cargoDHLCreate: 'DHL szállítás',
    cargoG4YCreate: 'G4Y szállítás',
    cargoMPLCreate: 'MPL szállítás',
    cargoUPSCreate: 'UPS szállítás',
    cargoMassPrint: 'Tömeges nyomtatás',
    cargoNormalPdf: 'Normál fuvarlevél',
    cargoNORMALWaybill: 'Normál szállítás - 1 út',
    cargoInversePdf: 'Inverz fuvarlevél',
    cargoINVERSEWaybill: 'Inverz szállítás - 2 út',
    changeStatus: 'Státusz léptetés',
    changeStatusCalc: 'Kalkuláció státusz léptetés',
    close: 'Bezárás',
    closeRequest: 'Lezárás',
    copyCustomerFullname: 'Ügyfél név másolása',
    createNew: 'Új létrehozása',
    csvExport: 'Adatok letöltése',
    declineDoc: 'Dokumentum elutasítása',
    delete: 'Törlés',
    deleteDoc: 'Dokumentum törlése',
    documentValidation: 'Dokumentumok validálása',
    download: 'Letöltés',
    downloadContract : 'Adásvételi szerződés letöltése',
    downloadReleasePdf: 'Átvételi elismervény letöltése',
    downloadWaybill: 'Fuvarlevél letöltése',
    edit: 'Szerkesztés',
    exportReportActions: 'Szakvélemény kezelés',
    finalStep: 'A szerződés aláírásra került és egy páldányt az ügyfélnek átadtam, a második példányt '
      + 'a készülék mellé betettem',
    forgotMyPassword: 'Elfelejtettem a jelszavam',
    grading: 'Minősítés',
    invoicingReceive: 'Számla adat fogadása',
    invoicingSend: 'Számla adat átadása',
    invoiceStatus: 'Számla státusz',
    littleCRON: 'GSM státuszok lekérdezése - OBSERVER',
    loanBack: 'Visszavétel',
    loanOut: 'Kiadás',
    loanSettings: 'Kölcsönkészülék kezelés',
    login: 'Bejelentkezés',
    logout: 'Kijelentkezés',
    massActions: 'Tömeges műveletek',
    next: 'Tovább',
    newItemInsert: 'Új tétel felvitel',
    ok: 'Rendben',
    open: 'Megnyitás',
    preorderStatus: 'PreOrder státusz',
    qouteActions: 'Árajánlat kezelés',
    resendConfirmationEmail: 'Visszaigazolás újraküldése',
    resendFinalOffer: 'Végleges ajánlat újraküldése',
    resendPreliminaryOffer: 'Előzetes ajánlat újraküldése',
    save: 'Mentés',
    saveSearch: 'Keresés mentése',
    savedSearches: 'Mentett keresések',
    send: 'Küldés',
    search: 'Keresés',
    seeContract: 'Szerződés megtekintése',
    seeDetails: 'Részletek megtekintése',
    tryAgain: 'Megpróbálom újra',
    uploadNewDocument: 'Új dokumentum feltöltése',
    view: 'Nézet',
  },
  calculatorStepper: {
    imei: 'Keresés',
    failures: 'Beszámíttatni kívánt készülék állapota',
    customer: 'Ügyfél adatok',
    contract: 'Szerződés véglegesítése',
    final: 'Kész',
  },
  calculationStatus: {
    option1: 'Kalkuláció státusz 1',
    option2: 'Kalkuláció státusz 2',
  },
  dateRangeButtons: {
    last3Months: 'Utolsó 3 hónap',
    last3Years: 'Utolsó 3 év',
    pastMonth: 'Előző hónap',
    pastWeek: 'Előző hét',
    pastYear: 'Előző év',
    thisMonth: 'Ez a hónap',
    thisWeek: 'Ez a hét',
    thisYear: 'Ez az év',
    today: 'Mai nap',
    yesterday: 'Tegnap',
  },
  deviceFinder: {
    generalText: 'Amennyiben a rendszer nem találja a megadott IMEI számot, a legördülő menüből válaszd ki készüléked '
      + 'gyártóját és pontos típusát. Amennyiben a legördülő menüben nem találod meg készüléked gyártóját '
      + 'és/vagy típusát, akkor az adott készüléket nincs módunkban megvásárolni.',
    noResultTextTitle: 'Sajnos rendszerük nem tudta beazonosítani készüléked, ezért kérlek az alábbi legördülő menüből, '
      + 'válaszd ki készüléked gyártóját és pontos típusát.',
    noResultTextSubtitle: 'Amennyiben a legördülő menüben nem találod meg készüléked gyártóját és/vagy típusát, akkor '
      + 'az adott készüléket nincs módunkban megvásárolni.',
  },
  documentStatus: {
    CANCELLED: 'Törölt',
    INVALID: 'Elutasított',
    PENDING: 'Elbárálásra vár',
    VALID: 'Érvényes',
  },
  editor: {
    title: 'Szerkeztő',
  },
  ERROR_MESSAGES: {
    cargoWaybillCreated: 'Sikertelen szállítás beküldés',
    cargoWaybillHash: 'Nem található a dokumentum',
    ccReleasePdfDownload: 'Az átvételi elismervény letöltése sikertelen. Kattints a '
      + 'Rendben gombra és az Ügyfélszolgálati listából töltsd le a dokumentumot.',
    emailSent: 'Sikertelen e-mail kiküldés',
    error: 'Sikertelen művelet',
    loanOut: 'Sikertelen kölcsönkészülék kiadás',
    loanReturn: 'Sikertelen kölcsönkészülék visszavétel',
    login: 'Sikertelen bejelentkezési kísérlet',
    resendConfirmationEmail: 'Visszaigazoló e-mail újra küldése nem sikerült!',
    save: 'A mentés nem sikerült. Próbálja meg pár perc múlva.',
    sendCustomerForm: 'Sikertelen beküldés',
    sendCalcDataForm: 'Sikertelen beküldés',
  },
  hints: {
    imeiHint: 'Lekérdezhető a *#06# kód beírással',
    imeiHintRequired: 'Kötelező kitölteni! Lekérdezhető a *#06# kód beírással',
    imeiHintOptional: 'Opcionális! Lekérdezhető a *#06# kód beírással',
    maxFileSizeRule: 'Maximális feltölthető fájlméret 5 MB!',
    maxFileSizeRuleOptional: 'Opcionális! Maximális feltölthető fájlméret 5 MB!',
    maxLenght22: 'Max. 22 karakter hosszú lehet.',
    minLenght3: 'Minimum 3 karakter hosszú kell, hogy legyen!',
    minLenght15: 'Kötelezően 15 karakter hosszú kell, hogy legyen!',
    noDataText: 'Nincs megjeleníthető adat.',
    noImei: 'Nincs megadva IMEI azonosító',
    notEditable: 'Nem szerkeszthető',
    onlyNumbers: 'Csak számokat írjon!',
    optionalField: 'Opcionális',
    requiredField: 'Kötelező kitölteni',
    search: 'Begépeléssel rá tud keresni a táblázatban megadott adatok bármelyikére',
    simpleDateReqMin: 'Kötelező kitölteni! Nem lehet korábbi a dátum, mint',
    simpleDateReqMinOptional: 'Opcionális! Nem lehet korábbi a dátum, mint',
    snHint: 'Ékezet nélküli kis- és nagybetűket valamint számokat tartalmazhat!',
    startTypingCity: 'Begépeléssel rá tud keresni a városra',
    startTypingPostalCode: 'Begépeléssel rá tud keresni az irányítószámra',
    startTypingStreet: 'Begépeléssel rá tud keresni az utcára',
  },
  invoiceStatus: {
    option1: 'Számla státusz 1',
    option2: 'Számla státusz 2',
  },
  labels: {
    accessories: 'Leadott tartozékok',
    addressTypeId: 'Cím típus Id',
    adminCalculatedPrice: 'Admin kalkulált ár',
    adminIndicativePrice: 'Admin indikatív ár',
    attachment: 'Melléklet',
    bankAccountNumber: 'Bankszámlaszám',
    billingAddress: 'Számlázási cím',
    billingName: 'Számlázási név',
    alert: 'Figyelem! Ez az utolsó lehetőség, hogy töröld a szerződést! Az ügyfél, miután a Letöltés '
      + 'gombra kattintottál, már nem tud elállni a szerződéstől!',
    b2bRef: 'B2B referencia',
    brand: 'Gyártó',
    businessForm: 'Cégforma',
    calculation: 'Kalkuláció',
    calculationId: 'Kalkuláció id',
    cargoDeliveryOut: 'Átadás futárszolgálattal',
    cargoDHLCreate: 'DHL szállítás',
    cargoG4YCreate: 'G4Y szállítás',
    cargoMPLCreate: 'MPL szállítás',
    cargoUPSCreate: 'UPS szállítás',
    cargoMassPrint: 'Tömeges nyomtatás',
    city: 'Város',
    comment: 'Megjegyzés',
    company: 'Cég | Vállakozás',
    companyName: 'Cég neve',
    companyShortName: 'Cég rövid neve',
    country: 'Ország',
    createdAt: 'Létrehozás dátuma',
    createdBy: 'Létrehozta',
    crmCustomerId: 'CRM Ügyfél Id',
    crmServiceId: 'CRM szolgáltatás',
    customerCenterDeliveryOut: 'Átadás az ügyfélszolgálaton',
    customerCenterPreRegRejectCode: 'Elutasítás oka',
    customerId: 'Ügyfél Id',
    customerIdMethod: 'Személyazonosság igazolás módja',
    customerIdNumber: 'Személyazonosságot igazoló okmány száma',
    customerIdType: 'Személyazonosságot igazoló okmány típusa',
    customerSellingPriceFrom: 'Eladási ár-tól',
    customerSellingPriceTo: 'Eladási ár-ig',
    dateOfPurchase: 'Vásárlás dátuma',
    deliveryAgreementsCode: 'Szerződés kód',
    code: 'Kód',
    detailedSearch: 'Részletes keresés',
    deviceColor: 'Készülék színe',
    deviceIdentifier: 'Készülék azonosító - IMEI/SN',
    deviceImei: 'Készülék IMEI azonosítója',
    deviceStorageCapacity: 'Tároló kapacitás',
    dolphinId: 'Dolphin Id',
    dolphinName: 'Dolphin név',
    email: 'E-mail cím',
    errorDescription: 'Hibaleírás',
    externalDataSourceSystem: 'Külső forrás rendszer',
    externalDataExternalId: 'Külső azonosító',
    firstName: 'Utónév',
    fonixId: 'Főnix Id',
    fonixSzolgaltatoiBeerkezes: 'Átvétel időpontja',
    fromDate: 'Kezdő dátum',
    gsmId: 'GSM Id',
    gsmType: 'GSM Típus',
    gsmWorksheetId: 'GSM munkalapszám',
    gsmWorksheetIdOrig: 'Előző GSM munkalapszám',
    houseNumber: 'Házszám',
    id: 'ID',
    imei: 'IMEI azonosító',
    imei1: 'IMEI1',
    imei2: 'IMEI2',
    indicativePrice: 'Indikatív ár',
    insuranceNumber: 'Biztosítási szám | Biztosítási szám/Kárszám | Kárszám',
    isActive: 'Aktív',
    isCompany: 'Cég, egyéni vállalkozás',
    isDefault: 'Alapértelmezett',
    itemNo: 'Cikkszám',
    language: 'Nyelv',
    lastName: 'Vezetéknév',
    linkUrl: 'Link url',
    loanCategoryCode: 'LOAN kategória kód',
    loanCategoryName: 'LOAN ketegória',
    logo: 'Logó',
    message: 'Üzenet',
    mobilProvider: 'Mobil szolgáltató',
    modifiedBy: 'Módosította',
    name: 'Név',
    namePrefixId: 'Név előtag id',
    networkLock: 'Hálózati zár',
    otherAddressInfo: 'Egyéb megjegyzés',
    otherDescriptionToCourier: 'Egyéb megjegyzés a futárnak',
    otherInfo: 'Egyéb megjegyzés',
    otherName: 'Egyéb megnevezés',
    parcelBillNumbers: 'Szállítólevél szám',
    partnerWorksheetNumber: 'Partner munkalapszám',
    password: 'Jelszó',
    passwordExpirationDate: 'Jelszó lejárat',
    paymentMethod: 'Fizetési mód',
    phoneNumber: 'Telefonszám',
    pickupCustomerCompanyName: 'Leadópont',
    postalCode: 'Irányítószám',
    privatePerson: 'Magánszemély',
    productGroup: 'Termékcsoport',
    productGroupId: 'Termékcsoport ID',
    productType: 'Készülék típus',
    productTypeFactoryName: 'Készülék gyártói név',
    productTypeId: 'Készülék típus ID',
    productTypeTradeName: 'Készülék kereskedelmi név',
    publicPlaceName: 'Közterület neve',
    publicPlaceType: 'Közterület jellege',
    rma: 'RMA szám',
    search: 'Keresés',
    searchName: 'Keresés neve',
    serviceName: 'Szolgáltatás neve',
    serialNr: 'Széria szám - SN',
    shippingAddress: 'Szállítási cím',
    sn: 'Gyári szám',
    status: 'Státusz',
    statusActual: 'Aktuális státusz',
    statusDate: 'Státusz dátum',
    statusId: 'Státusz id',
    statusIdNew: 'Új státusz id',
    statusNew: 'Új státusz',
    streetNumber: 'Házszám',
    taxNumber: 'Adószám',
    toDate: 'Vége dátum',
    type: 'Típus',
    uniqueIdentifier: 'Egyedi azonosító',
    uniqueIdentifier1: 'Egyedi azonosító 1',
    uniqueIdentifier2: 'Egyedi azonosító 2',
    uniqueIdentifier3: 'Egyedi azonosító 3',
    uniqueIdentifierIn1: 'Bejövő egyedi azonosító 1',
    uniqueIdentifierIn2: 'Bejövő egyedi azonosító 2',
    uniqueIdentifierIn3: 'Bejövő egyedi azonosító 3',
    uniqueIdentifierOut1: 'Kimenő egyedi azonosító 1',
    uniqueIdentifierOut2: 'Kimenő egyedi azonosító 2',
    uniqueIdentifierOut3: 'Kimenő egyedi azonosító 3',
    validFrom: 'Érvényesség kezdete',
    validTo: 'Érvényesség vége',
    voucherCode: 'Voucher kód',
    warrantyTicket: 'Jótállási jegy',
    warrantyType: 'Garancia típus',
    webportalId: 'Webportál id',
  },
  language: {
    hu: 'Magyar',
    huLocal: 'Magyar',
    en: 'English',
    enLocal: 'Angol',
    de: 'Deutsch',
    deLocal: 'Német',
  },
  LOADING_MESSAGES: {
    acceptFinaloffer: 'Végleges ajánlat elfogadása folyamatban',
    acceptPreoffer: 'Előzetes ajánlat elfogadása folyamatban',
    loading: 'Betöltés folyamatban',
    login: 'Bejelentkezés folyamatban',
    rejectFinaloffer: 'Végleges ajánlat elutasítása folyamatban',
    resendConfirmationEmail: 'Visszaigazoló e-mail küldése folyamatban',
    saveInProgess: 'Mentés folyamatban',
    sendInProgress: 'Küldés folyamatban',
    sendCustomerForm: 'Küldés folyamatban',
    sendCalcDataForm: 'Küldés folymatban',
    tableDataLoading: 'Adatok betöltése folyamatban',
    updateInProgess: 'Adatok frissítése folyamatban',
  },
  loan: {
    available: 'Elérhető',
    ledOut: 'Selejtes',
    toRepair: 'Javítás alatt',
  },
  navigation: {
    aboutUs: 'Rólunk',
    addressTypeDetailsPage: 'Cím típus részletei',
    addressTypeListPage: 'Cím típusok',
    allRequests: 'Összes igény',
    allActiveRequestListPage: 'Aktív igények',
    allClosedRequestListPage: 'Lezárt igények',
    allRequestDetailsPage: 'Összes igény - részletek',
    apple: 'iStuff',
    appleRequestDetailsPage: 'iStuff igény részletei',
    appleRequestListPage: 'iStuff igénylista',
    availableServiceDetailsPage: 'Elérhető szolgáltatás részletei',
    availableServiceListPage: 'Elérhető szolgáltatások',
    b2b: 'B2B',
    b2bRequestDetailsPage: 'B2B igény részletei',
    b2bRequestListPage: 'B2B igénylista',
    b2c: 'B2C',
    b2cRequestDetailsPage: 'B2C igény részletei',
    b2cRequestListPage: 'B2C igénylista',
    biztositos: 'Biztosítós szervizgarancia',
    biztositosRequestDetailsPage: 'Biztosítós szervizgarancia igény részletei',
    biztositosRequestListPage: 'Biztosítós szervizgarancia igénylista',
    brandDetailsPage: 'Gyártó részletei',
    brandListPage: 'Gyártók',
    brandProductGroupPage: 'Brand - Termékcsoport',
    brandProductGroupListPage: 'Gyártó - Termékcsoport',
    bravo: 'Bravo',
    bravoRequestDetailsPage: 'Bravo igény részletei',
    bravoRequestListPage: 'Bravo igénylista',
    businessFormDetailsPage: 'Cégforma részletei',
    businessFormListPage: 'Cégformák',
    buyback: 'Buyback',
    buybackCargoPlannerInListPage: 'Buyback - Begyűjtések',
    buybackCargoPlannerOutListPage: 'Buyback - Kiküldések',
    buybackClosedRequestListPage: 'Buyback - Lezárt igények',
    buybackOpenRequestListPage: 'Buyback - Nyitott igények',
    buybackRequestDetailsPage: 'Buyback igény részletei',
    calculation: 'Kalkuláció',
    calculator: 'Kalkulátor',
    cargoPlanner: 'Szállítástervező',
    cargoPlannerCargoDetailsPage: 'Szállítástervező - Igény részletei',
    cargoPlannerInListPage: 'Beszállítások - Begyűjtések',
    cargoPlannerOutListPage: 'Kiszállítások - Kiküldések',
    cargoPlannerCreateWaybillResultPage: 'Szállítástervező - Fuvarlevél eredmény',
    closedRequestList: 'Lezárt igények',
    complaintHandlingPolicy: 'Panaszkezelési szabályzat',
    contact: 'Kapcsolat',
    contactTypeDetailsPage: 'Kapcsolat felvétel típus részletei',
    contactTypeListPage: 'Kapcsolat felvétel típusok',
    cookieInfo: 'Cookie tájékoztató',
    crm: 'CRM',
    crmDocValidationPage: 'CRM - Dokumentumvalidáció',
    crmRequestDetailsPage: 'CRM igény részletei',
    crmRequestListPage: 'CRM - Igénylista',
    crmServiceDetailsPage: 'CRM Szolgáltatás részletei',
    crmServiceListPage: 'CRM Szolgáltatások',
    currencyDetailsPage: 'Pénznem részletei',
    currencyListPage: 'Pénznem',
    customer: 'Ügyfelek',
    customerCenter: 'Ügyfélszolgálat',
    customerCenterFormPage: 'Ügyfélszolgálat - igény leadás',
    customerCenterRequestDetailsPage: 'Ügyfélszolgálat - igény részletei',
    customerCenterRequestListPage: 'Ügyfélszolgálat igénylista',
    customerDetailsPage: 'Ügyfél részletei',
    customerListPage: 'Ügyfelek',
    customerContact: 'Kapcsolatfelvétel',
    customerTypeDetailsPage: 'Ügyfél típus részletei',
    customerTypeListPage: 'Ügyfél típusok',
    statusHandlerDeliveryNoteStatusListPage: 'Szállító levél státuszok',
    statusHandlerDeliveryNoteStatusRuleListPage: 'Szállító levél státusz szabályok',
    dailyLoanDeviceNeedsListPageMenuItem: 'Napi LOAN készülék igény',
    documents: 'Dokumentumok',
    documentsDocumentRecipientListPage: 'Dokumentum címzettek',
    documentsDocumentTemplateListPage: 'Dokumentum sablonok',
    documentsDocumentStatusListPage: 'Dokumentum státuszok',
    documentsDocumentTypeListPage: 'Dokumentum tipusok',
    employee: 'Dolgozói',
    employeeRequestDetailsPage: 'Dolgozói igény részletei',
    employeeRequestListPage: 'Dolgozói igénylista',
    ergo: 'ERGO',
    ergoNewRequestListPage: 'ERGO - Elfogadásra váró igények',
    ergoActiveRequestListPage: 'ERGO - Aktív igények',
    faq: 'Gyik',
    flip: 'Flip',
    flipRequestDetailsPage: 'Flip igény részletei',
    flipRequestListPage: 'Flip igénylista',
    followUs: 'Kövess minket!',
    home: 'Kezdőlap',
    howItWorks: 'Hogyan működik',
    huaCargoPlannerInListPage: 'Huawei - Begyűjtések',
    huaCargoPlannerOutListPage: 'Huawei - Kiküldések',
    imprint: 'Impesszum',
    loan: 'Kölcsönkészülék kezelés',
    loanAccessoriesListPage: 'LOAN - Tartozékok',
    loanAccessoriesListPageMenuItem: 'Tartozékok',
    loanAestheticFaultsListPage: 'LOAN - Esztétikai sérülések',
    loanAestheticFaultsListPageMenuItem: 'Esztétikai sérülések',
    loanAvailableListPage: 'LOAN - Elérhető készülékek',
    loanCategoriesListPageMenuItem: 'Kategóriák',
    loanCategoriesListPage: 'LOAN - Kategóriák',
    loanDeviceConditionsListPage: 'LOAN - Készülék állapotok',
    loanDeviceConditionsListPageMenuItem: 'Készülék állapotok',
    loanDeviceDiscardReasonsListPage: 'Készülék kiléptetési okok',
    loanDeviceDiscardReasonsListPageMenuItem: 'Készülék kiléptetési okok',
    loanDeviceStatusesListPage: 'Készülék státuszok',
    loanDeviceStatusesListPageMenuItem: 'Készülék státuszok',
    loanDevicesListPageMenuItem: 'Kölcsön készülékek',
    loanDeviceOutGivingListPageMenuItem: 'Kölcsön készülékek kiadása',
    loanDeviceRecaptureListPageMenuItem: 'Kölcsön készülékek visszavétele',
    loanDeviceBulkLoadingListPageMenuItem: 'Tömeges adatok betöltése',
    loanDeviceRiportListPageMenuItem: 'Riportok',
    loanDeviceImportListPageMenuItem: 'Import',
    loanDeviceStatusRuleListPageMenuItem: 'Státusz szabályok',
    loanStatusRuleListPageMenuItem: 'Szabályok',
    loanDocumentTypesListPage: 'Dokumentum típusok',
    loanDocumentTypesListPageMenuItem: 'Dokumentum típusok',
    loanOwnersListPage: 'Készülék tulajdonosok',
    loanOwnersListPageMenuItem: 'Készülék tulajdonosok',
    loanStatusesListPage: 'Státuszok',
    loanStatusesListPageMenuItem: 'Státuszok',
    loanDetailsPage: 'Kölcsönkészülék adatai',
    loanInUseListPage: 'LOAN - Kiadott készülékek',
    loanManagement: 'LOAN kezelés',
    maintenance: 'Karbantartás',
    marsh: 'Marsh',
    marshRequestDetailsPage: 'Marsh igény részletei',
    marshRequestListPage: 'Marsh igénylista',
    masterDatas: 'Törzsadatok',
    miele: 'Miele',
    mieleRequestDetailsPage: 'Miele igény részletei',
    mieleRequestListPage: 'Miele igénylista',
    mobilProviderDetailsPage: 'Mobil szolgáltató részletei',
    mobilProviderListPage: 'Mobil szolgáltatók',
    most: 'MOSTmobil',
    mostRequestDetailsPage: 'MOSTmobil igény részletei',
    mostRequestListPage: 'MOSTmobil igénylista',
    myDashboard: 'Vezérlőpult',
    namePrefixDetailsPage: 'Név elótag - részletek',
    namePrefixListPage: 'Név előtag',
    networkLockDetailsPage: 'Hálózati zár részletei',
    networkLockListPage: 'Hálózati zár',
    o2: 'O2',
    o2RequestDetailsPage: 'O2 igény részletei',
    o2RequestListPage: 'O2 igénylista',
    openRequestList: 'Nyitott igények',
    paymentMethodDetailsPage: 'Fizetési mód - részletek',
    paymentMethodListPage: 'Fizetési módok',
    prealert: 'Hartlauer',
    prealertRequestDetailsPage: 'Hartlauer igény részletei',
    prealertRequestListPage: 'Hartlauer igénylista',
    privacyNotice: 'Adatvédelmi tájékoztató',
    productGroupListPage: 'Termék csoportok',
    productGroupDetailsPage: 'Termék csoport részletei',
    requests: 'Igények',
    requestDetailsPage: 'Igény részletei',
    statusHandlerRequestStatusListPage: 'Igény státuszok',
    statusHandlerRequestStatusRuleListPage: 'Igény státusz szabályok',
    settings: 'Beállítások',
    siteMap: 'Oldaltérkép',
    statusHandler: 'Státuszkezelés',
    systemParameters: 'Rendszer beállítások',
    systemParamsDetailsPage: 'Rendszer paraméterek részletei',
    systemParamsListPage: 'Rendszer paraméterek',
    systemParametersFormTypeListPage: 'Űrlaptípusok',
    systemParametersLanguageListPage: 'Nyelvek',
    systemParametersSystemMessageListPage: 'Rendszerüzenetek',
    systemParametersSystemParameterListPage: 'Rendszerparaméterek',
    szervizgar: 'Szervizgar',
    szervizgarRequestDetailsPage: 'Szervizgar igény részletei',
    szervizgarRequestListPage: 'Szervizgar igénylista',
    telekom: 'Telekom',
    telekomRequestDetailsPage: 'Telekom igény részletei',
    telekomRequestListPage: 'Telekom igénylista',
    telenor: 'Telenor/Yettel',
    telenorRequestDetailsPage: 'Telenor/Yettel igény részletei',
    telenorRequestListPage: 'Telenor/Yettel igénylista',
    termsAndCond: 'Általános Szerződési Feltételek',
    unauthRolePage: 'Jogosultság hiba',
    vodafone: 'Vodafone',
    vodafoneRequestDetailsPage: 'Vodafone igény részletei',
    vodafoneRequestListPage: 'Vodafone igénylista',
    warrantyTypeListPage: 'Garancia típusok',
    warrantyTypeDetailsPage: 'Garancia típus részletei',
  },
  noDataText: {
    brand: 'Nincs megjeleníthető gyártó',
    noContracts: 'Nincs megjeleníthető szerződés',
    noSearchResult1: 'A keresett ',
    noSearchResult2: 'kifejezés nem található ',
    productGroups: 'Nincs megjeleníthető termékcsoport',
    productTypes: 'Nincs megjeleníthető terméktípus',
  },
  preorderStatus: {
    option1: 'Preorder státusz 1',
    option2: 'Preorder státusz 2',
  },
  RESULT_MESSAGES: {
    noEditableProjects: 'Nincs szerkeszthető projekt',
    tableNoResult: 'Nincs megjeleníthető adat',
  },
  stepper: {
    step1: 'Kalkulálj',
    step2: 'Küldd be a készüléked',
    step3: 'Bevizsgáljuk',
    step4: 'Ajánlat elfogadása',
    step5: 'Utaljuk a vételárat',
  },
  SUCCESS_MESSAGES: {
    acceptFinalOffer: '<p>Kedves Ügyfelünk,</p><p>Köszönjük, hogy elfogadtad ajánlatunkat! Az Adásvételi Szerződésben '
      + 'foglaltaknak megfelelően a TMX Mobile Solution Kft. 2 munkanapon belül az általad megadott bankszámlaszámra '
      + 'átutalja a készülék végleges ajánlat szerinti vételárát.</p>',
    acceptPreoffer: 'Sikeresen elfogadtad az előzetes ajánlatunkat! Hamarosan küldjük a futárt a készülékedért a megadott címre!',
    cargoWaybillCreated: 'Sikeres szállítás beküldés',
    ccRequestForm: 'Sikeres mentés. Töltsd le és nyomtasd ki az igényhez tartozó Átvételi elismervényt!',
    contactForm: 'Kérésed megérkezett hozzánk! A megadott e-mail címen hamarosan felvesszük veled a kapcsolatot!',
    emailSent: 'Sikeres e-mail kiküldés',
    finalMessage: '<div class="text-h4 mb-4">Sikeres beküldés!</div>Lehet még ide írni valamit, hogy mennyire ügyes volt és majd megy az előzetes ajánlat levél!<br>PusziPacsi',
    finalMessageDialog: 'Kérésed megérkezett hozzánk! A megadott e-mail címre hamarosan küldjük az előzetes ajánlatot!',
    loanOut: 'Sikeres kölcsönkészülék kiadás',
    loanReturn: 'Sikeres kölcsönkészülék visszavétel',
    login: 'Sikeres bejelentkezés',
    ok: 'Sikeres művelet',
    rejectFinalOffer: '<p>Kedves Ügyfelünk,</p><p>Az általunk adott árajánlatot Ön elutasította. Készülékét egy '
      + 'héten belül visszaküldjük a felvételi címre. A visszaküldés szállítási költségét Önnek kell állnia, ami '
      + 'bruttó 1.700 Ft, amit a futárnak kell kifizetnie kiszállításkor.</p>',
    resendConfirmationEmail: 'Visszaigazoló e-mail újra küldése sikeres!',
    save: 'Sikeres mentés',
    sendCustomerForm: 'Sikeres beküldés',
    sendCalcDataForm: 'Sikeres beküldés',
  },
  $vuetify: {
    badge: 'Jelvény',
    close: 'Bezárás',
    dataIterator: {
      noResultsText: 'Nincs egyező találat',
      loadingText: 'Betöltés...',
    },
    dataTable: {
      itemsPerPageText: 'Elem oldalanként:',
      ariaLabel: {
        sortDescending: 'Csökkenő sorrendbe rendezve.',
        sortAscending: 'Növekvő sorrendbe rendezve.',
        sortNone: 'Rendezetlen.',
        activateNone: 'Rendezés törlése.',
        activateDescending: 'Aktiváld a csökkenő rendezésért.',
        activateAscending: 'Aktiváld a növekvő rendezésért.',
      },
      sortBy: 'Rendezés',
    },
    dataFooter: {
      itemsPerPageText: 'Elem oldalanként:',
      itemsPerPageAll: 'Mind',
      nextPage: 'Következő oldal',
      prevPage: 'Előző oldal',
      firstPage: 'Első oldal',
      lastPage: 'Utolsó oldal',
      pageText: '{0}-{1} / {2}',
    },
    datePicker: {
      itemsSelected: '{0} kiválasztva',
      nextMonthAriaLabel: 'Következő hónap',
      nextYearAriaLabel: 'Következő év',
      prevMonthAriaLabel: 'Előző hónap',
      prevYearAriaLabel: 'Előző év',
    },
    noDataText: 'Nincs elérhető adat',
    carousel: {
      prev: 'Előző',
      next: 'Következő',
      ariaLabel: {
        delimiter: 'Dia {0}/{1}',
      },
    },
    calendar: {
      moreEvents: '{0} további',
    },
    fileInput: {
      counter: '{0} fájl',
      counterSize: '{0} fájl ({1} összesen)',
    },
    timePicker: {
      am: 'de',
      pm: 'du',
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Oldal navigáció',
        next: 'Következő oldal',
        previous: 'Előző oldal',
        page: 'Menj a(z) {0}. oldalra',
        currentPage: 'Aktuális oldal: {0}',
      },
    },
    rating: {
      ariaLabel: {
        icon: 'Rating {0} of {1}',
      },
    },
  },
};
