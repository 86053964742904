<template>
  <div v-frag>
    <v-btn height="40"
           depressed
           rounded
           icon
           :disabled="!$store.state.appStore.appSystemInfo"
           @click="toggleSnackbar">
      <v-badge v-model="showBadge"
               overlap
               color="red"
               content="1">
        <v-icon color="white" size="26">
          mdi-bell
        </v-icon>
      </v-badge>
    </v-btn>
    <SystemMessageSnackbar :is-visible.sync="showSnackbar"
                           :title="$store.getters.getAppSystemInfoContent.title || ''"
                           :description="$store.getters.getAppSystemInfoContent.description || ''" />
  </div>
</template>

<script>
import SystemMessageSnackbar from '@/components/shared/SystemMessageSnackbar.vue';

export default {
  name: 'Notifications',
  components: {
    SystemMessageSnackbar,
  },
  props: {
    //
  },
  data() {
    return {
      showSnackbar: false,
      showBadge: false,
    };
  },
  methods: {
    toggleSnackbar() {
      this.showSnackbar = !this.showSnackbar;
      this.showBadge = false;
    },
  },
  watch: {
    '$store.state.appStore.appSystemInfo': function (value) {
      if (value && value.status === this.$HTTP_OK) {
        this.showBadge = true;
        this.showSnackbar = true;
      }
    },
  },
};
</script>

<style scoped>

</style>
