import axios from 'axios';
import { getToken } from './tokenHelper';
import { BASE_URL, LION_URL } from 'src/config/config';
import i18n from '../i18n';

const instance = axios.create({
  timeout: 200000,
});

console.log('BASE_URL: ', BASE_URL);
console.log('LION_URL: ', LION_URL);

/**
 *
 * Általános HTTP kérés headerjének beállítása
 *
 * @param needAuth boolean
 *
 */
async function setHeaders(needAuth) {
  // const token = await getToken();
  const token = '5ae39ff10cafd40f72f65ec1bfe35b505954967a307256657b1aa4f07d35c73c255d553da97e4db5d311f8643cfb282a5c993ff099db1cbed3a4f072d964b9f4';
  const langCode = i18n.locale.substring(0, 2);
  if (token && needAuth) {
    return { 'Token': `${token}`, 'Language': `${langCode}` };
  }
  return { 'Language': `${langCode}` };
}

async function setAPIurl(apiName) {
  let apiurl = BASE_URL;
  switch (apiName) {
    case 'CRM':
      apiurl = BASE_URL;
      break;
    case 'LION':
      apiurl = LION_URL;
      break;
    default:
      apiurl = BASE_URL;
  }
  return apiurl;
}

/**
 *
 * File letöltéshez a kérés headerjének beállítása
 *
 * @param needAuth
 */
async function setFileDownloadHeaders(needAuth = false) {
  const token = await getToken();
  const langCode = i18n.locale.substring(0, 2);
  if (token && needAuth) {
    return { 'Token': `${token}`, 'Language': `${langCode}`, 'Content-Type': 'multipart/form-data' };
  }
  return { 'Language': `${langCode}`, 'Content-Type': 'multipart/form-data' };
}

/**
 *
 * GET híváshoz
 *
 * @param url string
 * @param params any
 * @param needAuth boolean
 * @param apiName string
 */
export async function APIGET(url, params, needAuth = false, apiName = 'CRM') {
  try {
    const htmlResponse = await instance({
      method: 'get',
      baseURL: await setAPIurl(apiName),
      url: `${await setAPIurl(apiName)}/${url}`,
      headers: await setHeaders(needAuth),
      params,
      validateStatus: function (status) {
        return status >= 200;
      },
    });
    console.log(`%cAPIGET - ${url} response:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    console.log(htmlResponse);
    return htmlResponse;
  } catch (error) {
    console.log(`%cAPIGET - ${url} catch error:`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    console.log(error);
    return {
      status: 999,
      data: [],
      message: `CATCH error: ${error}`,
    };
  }
}

/**
 *
 * POST híváshoz
 *
 * @param url string
 * @param body any
 * @param needAuth boolean
 * @param apiName string
 */
export async function APIPOST(url, body, needAuth = false, apiName = 'CRM') {
  try {
    const htmlResponse = await instance({
      method: 'post',
      baseURL: await setAPIurl(apiName),
      url: `${await setAPIurl(apiName)}/${url}`,
      headers: await setHeaders(needAuth),
      data: body,
      validateStatus: function (status) {
        return status >= 200;
      },
    });
    console.log(`%cAPIPOST - ${url} response:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    console.log(htmlResponse);
    return htmlResponse;
  } catch (error) {
    console.log(`%cAPIPOST - ${url} catch error:`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    console.log(error);
    return {
      status: 999,
      data: [],
      message: `CATCH error: ${error}`,
    };
  }
}

/**
 * PATCH híváshoz
 *
 * @param url
 * @param body
 * @param needAuth
 * @param apiName
 * @returns {Promise<{data: *[], message: string, status: number}|*>}
 * @constructor
 */
export async function APIPATCH(url, body, needAuth = false, apiName = 'CRM') {
  try {
    const htmlResponse = await instance({
      method: 'patch',
      baseURL: await setAPIurl(apiName),
      url: `${await setAPIurl(apiName)}/${url}`,
      headers: await setHeaders(needAuth),
      data: body,
      validateStatus: function (status) {
        return status >= 200;
      },
    });
    console.log(`%cAPIPATCH - ${url} response:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    console.log(htmlResponse);
    return htmlResponse;
  } catch (error) {
    console.log(`%cAPIPATCH - ${url} catch error:`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    console.log(error);
    return {
      status: 999,
      data: [],
      message: `CATCH error: ${error}`,
    };
  }
}

/**
 * PUT híváshoz
 *
 * @param url
 * @param body
 * @param needAuth
 * @param apiName
 * @returns {Promise<{data: *[], message: string, status: number}|*>}
 * @constructor
 */
export async function APIPUT(url, body, needAuth = false, apiName = 'CRM') {
  try {
    const htmlResponse = await instance({
      method: 'put',
      baseURL: await setAPIurl(apiName),
      url: `${await setAPIurl(apiName)}/${url}`,
      headers: await setHeaders(needAuth),
      data: body,
      validateStatus: function (status) {
        return status >= 200;
      },
    });
    console.log(`%cAPIPATCH - ${url} response:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    console.log(htmlResponse);
    return htmlResponse;
  } catch (error) {
    console.log(`%cAPIPATCH - ${url} catch error:`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    console.log(error);
    return {
      status: 999,
      data: [],
      message: `CATCH error: ${error}`,
    };
  }
}

/**
 * DELETE híváshoz
 *
 * @param url
 * @param params
 * @param needAuth
 * @param apiName
 * @returns {Promise<{data: *[], message: string, status: number}|*>}
 * @constructor
 */
export async function APIDELETE(url, params, needAuth = false, apiName = 'CRM') {
  try {
    const htmlResponse = await instance({
      method: 'delete',
      baseURL: await setAPIurl(apiName),
      url: `${await setAPIurl(apiName)}/${url}`,
      headers: await setHeaders(needAuth),
      params,
      validateStatus: function (status) {
        return status >= 200;
      },
    });
    console.log(`%cAPIDELETE - ${url} response:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    console.log(htmlResponse);
    return htmlResponse;
  } catch (error) {
    console.log(`%cAPIDELETE - ${url} catch error:`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    console.log(error);
    return {
      status: 999,
      data: [],
      message: `CATCH error: ${error}`,
    };
  }
}

/**
 *
 * File letöltéshez
 *
 * @param url string
 * @param body any
 * @param needAuth boolean
 * @param apiName string
 * @param method string = get | post
 * @param type strint = OPEN | SAVE
 */
export async function APIFILEDOWNLOAD(url, body = {}, needAuth = false, apiName = 'CRM', method='get', type = 'OPEN') {
  try {
    const htmlResponse = await instance({
      method: method,
      baseURL: await setAPIurl(apiName),
      url: `${await setAPIurl(apiName)}/${url}`,
      headers: await setFileDownloadHeaders(needAuth),
      responseType: 'blob',
      data: body,
      validateStatus: function (status) {
        return status >= 200;
      },
    });
    console.log(`%cAPIFILEDOWNLOAD - ${url} response:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    console.log(htmlResponse.status);
    if (htmlResponse.status === 200) {
      const responseHeader = new Headers(htmlResponse.headers);
      const contentType = responseHeader.get('Content-Type');
      console.log('contentType: ', contentType);
      const i = contentType.lastIndexOf('/');
      let fileType = contentType.substr(i + 1);
      if (contentType.includes('csv')) {
        fileType = 'csv';
      }
      console.log('fileType: ', fileType);
      const now = new Date().toISOString().substr(0, 10);
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([htmlResponse.data], { type: `${contentType}` });
      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      // MENTÉSES verzió
      if (type === 'SAVE') {
        const url = window.URL.createObjectURL(new Blob([htmlResponse.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `file@${now}.${fileType}`);
        document.body.appendChild(link);
        link.click();
      } else {
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.target = '_blank';
        link.click();
        // setTimeout(() => window.URL.revokeObjectURL(data), 2000);
      }
    }
    return htmlResponse.status;
  } catch (error) {
    console.log(`%cAPIFILEDOWNLOAD - ${url} catch error:`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    console.log(error);
    return {
      data: {
        status: 999,
        data: [],
        message: `CATCH error: ${error}`,
      },
    };
  }
}
