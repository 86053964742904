const calcData = '56q3G#"a%_';
const damages = 'je>!2d%XQhJ';

export const getCalcData = () => {
  const data = window.sessionStorage.getItem(calcData);
  return JSON.parse(data);
}

export const saveCalcData = item => {
  if (!item) {
    return;
  }
  window.sessionStorage.setItem(calcData, JSON.stringify(item));
};

export const destroyCalcData = () => {
  window.sessionStorage.removeItem(calcData);
};

export const getDamages = () => {
  const data = window.sessionStorage.getItem(damages);
  return JSON.parse(data);
}

export const saveDamages = item => {
  if (!item) {
    return;
  }
  window.sessionStorage.setItem(damages, JSON.stringify(item));
};

export const destroyDamages = () => {
  window.sessionStorage.removeItem(damages);
};

export default {
  getCalcData,
  saveCalcData,
  destroyCalcData,
  getDamages,
  saveDamages,
  destroyDamages,
};
