<template>
  <div v-frag>
    <v-app-bar app class="primary" elevation="0">
      <template v-if="$vuetify.breakpoint.smAndDown">
        <v-app-bar-nav-icon @click.stop="navigationDrawerIsOpened = !navigationDrawerIsOpened"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-img :src="`${publicPath}images/mamlogowhite88x60.png`" height="40" contain @click="$router.push('/')"
               style="cursor: pointer;"/>
        <v-spacer></v-spacer>
        <Notifications />
      </template>
      <template v-else>
        <v-container>
          <v-row>
            <div>
              <v-img :src="`${publicPath}images/mamlogowhite88x60.png`" height="40" contain @click="$router.push('/')"
                     style="cursor: pointer;"/>
            </div>
            <v-spacer></v-spacer>
            <template v-for="(item, index) in $store.state.outerNavigation.navbar">
              <v-btn :key="index"
                     @click="item.route.name !== $route.name ? item.click() : doNothing"
                     class="mx-2"
                     height="40"
                     :class="item.route.name === $route.name ? 'opacity-1' : ''"
                     plain
                     :outlined="item.route.name === 'Calculation'"
                     dark
                     depressed
                     text>
                {{ $t(`${item.text}`) }}
              </v-btn>
            </template>
            <Notifications />
          </v-row>
        </v-container>
      </template>
    </v-app-bar>
    <v-navigation-drawer v-model="navigationDrawerIsOpened" app disable-resize-watcher class="pa-4">
      <div class="my-4">
        <v-img :src="`${publicPath}images/mamlogowhite88x60.png`" height="60" contain @click="$router.push('/')"
               style="cursor: pointer;" class="mx-auto"/>
      </div>
      <div class="my-4 text-center text-h6">
        {{ $ND_PAGE_TITLE }}
      </div>
      <template v-for="(item, index) in $store.state.outerNavigation.navbar">
        <v-btn :key="index"
               @click="item.route.name !== $route.name ? item.click() : doNothing"
               class="my-2"
               :class="item.route.name === $route.name ? 'opacity-1' : ''"
               plain
               depressed
               block
               text>
          {{ $t(`${item.text}`) }}
        </v-btn>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Notifications from 'src/components/shared/Notifications.vue';

export default {
  name: 'InnerNavBar',
  components: {
    Notifications,
  },
  props: {
    //
  },
  mounted() {
    //
  },
  data() {
    return {
      navigationDrawerIsOpened: false,
    };
  },
  methods: {
    doNothing() {
      //
    },
    async logout() {
      await this.$store.dispatch('Logout');
      this.$router.push({ name: 'Home' });
    },
  },
};
</script>

<style scoped>

</style>
