<template>
  <v-app-bar app color="primary">
    <v-container>
      <v-row justify="center">
        <template v-for="(item, index) in $store.state.innerNavigation.navbar">
          <v-btn :key="index"
                 @click="item.route.name !== $route.name ? item.click() : doNothing"
                 class="mx-2"
                 :class="item.route.name === $route.name ? 'opacity-1' : ''"
                 plain
                 depressed
                 text
                 dark>
            {{ $t(`${item.text}`) }}
          </v-btn>
        </template>
        <v-btn @click="logout"
               class="mx-2"
               plain
               depressed
               text
               dark>
          {{ $t('button.logout') }}
        </v-btn>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { destroyToken } from 'src/utils/tokenHelper';

export default {
  name: 'InnerNavBar',
  components: {
    //
  },
  props: {
    //
  },
  mounted() {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    doNothing() {
      //
    },
    async logout() {
      await this.$store.dispatch('Logout');
      this.$router.push({ name: 'Home' });
    },
  },
};
</script>

<style scoped>

</style>
