/* eslint-disable no-shadow */

// initial state
import Vue from 'vue';
import { APIGET } from 'src/utils/apiHelper.js';
import appConfig from 'src/config/appConfig.js';

const state = {
  //
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async CheckImeiByLuhn(context, credentials) {
    const response = await APIGET(`device-management/luhn/${credentials}/hu`, {}, false, 'CRM');
    return response.status;
  },
  async GetBrands(context, credentials) {
    const response = await APIGET(`buyback/brands?availableServiceId=${Vue.prototype.$BB_AVS}`,
      {}, true, 'CRM');
    return response.data;
  },
  async GetBrandsOfProductGroup(context, credentials) {
    const response = await APIGET(`buyback/brands?availableServiceId=${Vue.prototype.$BB_AVS}&productGroupId=${credentials}`,
      {}, true, 'CRM');
    return response.data;
  },
  async GetDeviceState(context, credentials) {
    const response = await APIGET(`buyback/device-state/avs/${credentials.avs}?productGroupId=${credentials.productGroupId}`,
      {}, true, 'CRM');
    return response.data;
  },
  async GetProductGroupsOfAVSID(context, credentials) {
    const response = await APIGET(`buyback/product-groups/?availableServiceId=${Vue.prototype.$BB_AVS}`,
      {}, true, 'CRM');
    return response.data;
  },
  async GetProductGroupOfBrand(context, credentials) {
    const response = await APIGET(`brands/${credentials}/product-groups`, {}, false, 'CRM');
    return response.data;
  },
  async GetAllProductTypesOfBrand(context, credentials) {
    // const response = await APIGET(`buyback/brands/${credentials}/product-types`, {}, true, 'CRM');
    const response = await APIGET(`buyback/product-types/?availableServiceId=${Vue.prototype.$BB_AVS}&brandId=${credentials}`,
      {}, true, 'CRM');
    return response.data;
  },
  async GetProductTypesOfBrandByProductGroupId(context, credentials) {
    const response = await APIGET(`buyback/product-types/?availableServiceId=${Vue.prototype.$BB_AVS}&productGroupId=${credentials.productGroupId}&brandId=${credentials.brandId}`,
      {}, true, 'CRM');
    //const response = await APIGET(`brands/${credentials.brandId}/product-types/${credentials.productGroupId}`,
    //  {}, false, 'CRM');
    return response.data;
  },
  async GetDeviceByImei(context, credentials) {
    const response = await APIGET(`buyback/imei-info/${credentials}`, {}, true, 'CRM');
    return response.data;
  },
  async GetDeviceModelDatas(context, credentials) {
    const response = await APIGET(`buyback/product-types/${credentials}/model-data`, {}, false, 'CRM');
    return response.data;
  },
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
