<template>
  <div v-frag>
    <!-- <OuterNavBar /> -->
    <template v-if="isLoading">
      <v-main>
        <v-container fluid class="fill-height">
          <v-row class="fill-height" align="center" justify="center">
            <v-progress-circular indeterminate
                                 size="120"
                                 width="4"
                                 color="primary">
            </v-progress-circular>
          </v-row>
        </v-container>
      </v-main>
    </template>
    <template v-else>
      <v-main>
        <router-view></router-view>
      </v-main>
    </template>
    <OuterFooter />
  </div>
</template>

<script>
import OuterFooter from 'src/components/shared/OuterFooter.vue';
import OuterNavBar from 'src/components/navigation/OuterNavBar.vue';

export default {
  name: 'OuterLayout',
  components: {
    OuterFooter, OuterNavBar,
  },
  props: {
    //
  },
  async beforeMount() {
    await this.$store.dispatch('initApp');
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
