const userType = '-X(e4)Db~!2S';
const expTime = '8{M@d-47GX_';

export const getUserType = () => window.sessionStorage.getItem(userType);

export const saveUserType = type => {
  if (!type) {
    return;
  }
  window.sessionStorage.setItem(userType, type);
};

export const destroyUserType = () => {
  window.sessionStorage.removeItem(userType);
};

export const getExpTime = () => window.sessionStorage.getItem(expTime);

export const saveExpTime = time => {
  if (!time) {
    return;
  }
  window.sessionStorage.setItem(expTime, time);
};

export const destroyExpTime = () => {
  window.sessionStorage.removeItem(expTime);
};

export default {
  getUserType,
  saveUserType,
  destroyUserType,
  getExpTime,
  saveExpTime,
  destroyExpTime,
};
