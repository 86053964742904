export class CommonUtils {

  static isEmpty(element) {
    return ((element === undefined)
      || (element === 'undefined')
      || (element === null)
      || (element === '')
      || (element === 'null')
      || (element === 'NaN')
      || (typeof element === 'number' && Number.isNaN( element ))
    );
  }

  static isNotEmpty(element) {
    return !this.isEmpty(element);
  }

}
