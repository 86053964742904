<template>
  <div v-frag>
    <v-container fluid class="footerBase">
      <!--<v-container>
        <v-row>
          <v-col cols="12" md="6" lg="3">
            <div>
              <v-img :src="`${publicPath}images/mamlogowhite440x300.png`" height="80" contain />
            </div>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <div class="text-body-1 white--text mb-3 font-weight-bold text-uppercase">
              {{ $t('navigation.documents') }}
            </div>
            <ul style="list-style: none;" class="px-0">
              <template v-for="(item, index) in $store.state.outerNavigation.footerGeneral">
                <li :key="index">
                  <a @click="item.click()" class="white--text text-decoration-none text-body-2 footer-link">
                    {{ $t(`${item.text}`) }}
                  </a>-->
                  <!--router-link :to="{ name: item.route.name }" :class="item.route.name === $route.name ? 'opacity-1' : ''"
                               class="white--text text-decoration-none text-body-2 footer-link">
                    {{ $t(`${item.text}`) }}
                  </router-link-->
                <!--</li>
              </template>
            </ul>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <div class="text-body-1 white--text mb-3 font-weight-bold text-uppercase">
              {{ $t('navigation.siteMap') }}
            </div>
            <ul style="list-style: none;" class="px-0">
              <template v-for="(item, index) in $store.state.outerNavigation.navbar">
                <li :key="index">
                  <a @click="item.click()" class="white--text text-decoration-none text-body-2 footer-link">
                    {{ $t(`${item.text}`) }}
                  </a>-->
                  <!--router-link :to="{ name: item.route.name }" :class="item.route.name === $route.name ? 'opacity-1' : ''"
                               class="white--text text-decoration-none text-body-2 footer-link">
                    {{ $t(`${item.text}`) }}
                  </router-link-->
                <!--</li>
              </template>
            </ul>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <div class="text-body-1 white--text mb-3 font-weight-bold text-uppercase">
              {{ $t('navigation.followUs') }}
            </div>
            <div>
              <template v-for="(item, index) in $store.state.outerNavigation.socialMedia">
                <v-btn :key="`sm${index}`"
                       icon
                       dark
                       class="mr-2"
                       color="white"
                       @click="item.click()">
                  <v-icon>
                    mdi-{{ item.icon }}
                  </v-icon>
                </v-btn>
              </template>
            </div>
          </v-col>
        </v-row>
      </v-container> -->
      <v-container>
        <!--<v-divider style="width: 85%;" dark class="mx-auto mb-3"/>-->
        <v-container class="text-center white--text text-caption py-0">
          <div>
            {{ $store.getters.getAppSystemInfoCurrentVersion }}
          </div>
          <div>
            Copyright &copy; {{ year }} TMX Mobile Solution Szerviz Kft. Minden jog fenntartva.
          </div>
        </v-container>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'OuterFooter',
  components: {
    //
  },
  props: {
    //
  },
  beforeMount() {
    this.year = new Date().getFullYear();
  },
  data() {
    return {
      year: 2022,
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
