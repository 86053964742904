/* eslint-disable no-shadow */

// initial state
import Vue from 'vue';
import router from 'src/router/router.js';

const state = {
  navbar: [
    {
      text: 'navigation.home',
      route: {
        external: false,
        path: '',
        name: 'Home',
      },
      click: () => {
        router.push({ name: 'Home' })
      },
    },
    {
      text: 'navigation.howItWorks',
      route: {
        external: false,
        path: '',
        name: 'HogyanMukodikPage',
      },
      click: () => {
        router.push({ name: 'HogyanMukodikPage' })
      },
    },
    {
      text: 'navigation.aboutUs',
      route: {
        external: false,
        path: '',
        name: 'RolunkPage',
      },
      click: () => {
        router.push({ name: 'RolunkPage' })
      },
    },
    {
      text: 'navigation.faq',
      route: {
        external: false,
        path: '',
        name: 'GyikPage',
      },
      click: () => {
        router.push({ name: 'GyikPage' })
      },
    },
    {
      text: 'navigation.contact',
      route: {
        external: false,
        path: '',
        name: 'KapcsolatPage',
      },
      click: () => {
        router.push({ name: 'KapcsolatPage' })
      },
    },
    /*{
      text: 'Piactér',
      route: {
        external: false,
        path: '',
        name: 'Piacter',
      },
      click: async () => {
        if (router.currentRoute.name !== 'Home') {
          await router.push({ name: 'Home' });
        }
        document.getElementById('deviceToBuy').scrollIntoView({ behavior: "smooth" });
      },
    },*/
    /*{
      text: 'navigation.calculation',
      route: {
        external: false,
        path: '',
        name: 'Calculation',
      },
      click: async () => {
        if (router.currentRoute.name !== 'partnerHome-cashback') {
          await router.push({ name: 'partnerHome-cashback', params: { partnerName: 'kalkulacio' } });
        }
        // document.getElementById('deviceToBuy').scrollIntoView({ behavior: "smooth" });
      },
    },*/
    /*{
      text: 'Piactér',
      route: {
        external: false,
        path: '',
        name: 'Piacter',
      },
      click: async () => {
        if (router.currentRoute.name !== 'Home') {
          await router.push({ name: 'Home' });
        }
        document.getElementById('deviceToBuy').scrollIntoView({ behavior: "smooth" });
      },
    },*/
  ],
  footerGeneral: [
    {
      text: 'navigation.complaintHandlingPolicy',
      route: {
        external: false,
        path: '',
        name: 'Home',
      },
      click: () => {
        window.open(`/${Vue.prototype.$COMPLAINT_HANDLING_POLICY}`, '_blank');
      },
    },
    {
      text: 'navigation.privacyNotice',
      route: {
        external: false,
        path: '',
        name: 'Home',
      },
      click: () => {
        window.open(`/${Vue.prototype.$PRIVACY_POLICY}`, '_blank');
      },
    },
    {
      text: 'navigation.termsAndCond',
      route: {
        external: false,
        path: '',
        name: 'Home',
      },
      click: () => {
        window.open(`/${Vue.prototype.$TERMS_AND_CONDS}`, '_blank');
      },
    },
    /*{
      text: 'navigation.cookieInfo',
      route: {
        external: false,
        path: '',
        name: 'Home',
      },
      click: () => {
        router.push({ name: 'Home' });
      },
    },*/
    {
      text: 'navigation.imprint',
      route: {
        external: false,
        path: '',
        name: 'Home',
      },
      click: () => {
        router.push({ name: 'ImpresszumPage' });
      },
    },
  ],
  socialMedia: [
    {
      icon: 'facebook',
      text: '',
      click: () => {
        window.open('https://www.facebook.com/tmxszerviz', '_blank');
      },
    }
  ]
};

// getters
const getters = {
  //
};

// actions
const actions = {
  //
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
