import { render, staticRenderFns } from "./OuterFooter.vue?vue&type=template&id=6cff131f&scoped=true&"
import script from "./OuterFooter.vue?vue&type=script&lang=js&"
export * from "./OuterFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cff131f",
  null
  
)

export default component.exports