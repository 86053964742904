import { CommonUtils } from 'src/utils/commonUtils.js';
import Vue from 'vue';

export default Vue.mixin({
  data() {
    return {
      selectFieldOptions: {
        brands: [],
        campaigns: [],
        colors: [],
        customerIdCards: [],
        productGroups: [],
        productTypes: [],
        sellingProductGroups: [],
        storageCapacity: [],
      },
    };
  },
  methods: {
    async SetSelectFieldOptionsBrands(orderByPropertyName = 'name') {
      this.selectFieldOptions.brands = [];
      const response = await this.$store.dispatch('GetBrands');
      console.log('response', response);
      if (response.status === this.$HTTP_OK) {
        this.selectFieldOptions.brands = response.data.sort((a, b) => ((a[orderByPropertyName] > b[orderByPropertyName]) ? 1 : -1));
      } else {
        //
      }
    },
    async SetSelectFieldOptionsBrandsByProductGroupId(productGroupId, orderByPropertyName = 'name') {
      this.selectFieldOptions.brands = [];
      const response = await this.$store.dispatch('GetBrandsOfProductGroup', productGroupId);
      console.log('response', response);
      if (response.status === this.$HTTP_OK) {
        this.selectFieldOptions.brands = response.data.sort((a, b) => ((a[orderByPropertyName] > b[orderByPropertyName]) ? 1 : -1));
      } else {
        //
      }
    },
    async SetSelectFieldOptionsIdentifierCardTypes() {
      const response = await this.$store.dispatch('GetCustomerIdentifierCardTypes');
      if (response.status === 200 ) {
        this.selectFieldOptions.customerIdCards = response.data;
      }
    },
    async SetSelectFieldOptionsProductGroupsAvsId(orderByPropertyName = 'name') {
      this.selectFieldOptions.productGroups = [];
      const response = await this.$store.dispatch('GetProductGroupsOfAVSID');
      if (response.status === this.$HTTP_OK) {
        this.selectFieldOptions.productGroups = response.data.sort((a, b) => ((a[orderByPropertyName] > b[orderByPropertyName]) ? 1 : -1));
      } else {
        //
      }
    },
    /*async SetSelectFieldOptionsProductGroupsBrandId(brandid, orderByPropertyName = 'name') {
      this.selectFieldOptions.productGroups = [];
      const response = await this.$store.dispatch('GetProductGroupOfBrand', brandid);
      if (response.status === this.$HTTP_OK) {
        this.selectFieldOptions.productGroups = response.data.sort((a, b) => ((a[orderByPropertyName] > b[orderByPropertyName]) ? 1 : -1));
      } else {
        //
      }
    },*/
    async SetSelectFieldOptionsProductTypesByBrandId(brandid, orderByPropertyName = 'deviceName') {
      this.selectFieldOptions.productTypes = [];
      const response = await this.$store.dispatch('GetAllProductTypesOfBrand', brandid);
      if (response.status === this.$HTTP_OK) {
        this.selectFieldOptions.productTypes = response.data.sort((a, b) => ((a[orderByPropertyName] > b[orderByPropertyName]) ? 1 : -1));
      } else {
        //
      }
    },
    async SetSelectFieldOptionsProductTypesByBrandIdAndProductGroupId(brandId, productGroupId, orderByPropertyName = 'name') {
      this.selectFieldOptions.productTypes = [];
      const response = await this.$store.dispatch('GetProductTypesOfBrandByProductGroupId',
        { brandId: brandId, productGroupId: productGroupId });
      if (response.status === this.$HTTP_OK) {
        this.selectFieldOptions.productTypes = response.data.sort((a, b) => ((a[orderByPropertyName] > b[orderByPropertyName]) ? 1 : -1));
      } else {
        //
      }
    },
    async SetSelectFieldOptionsSellingProductGroupsAvsId(orderByPropertyName = 'name') {
      this.selectFieldOptions.sellingProductGroups = [];
      const response = await this.$store.dispatch('GetSellingProductGroups');
      if (response.status === this.$HTTP_OK) {
        this.selectFieldOptions.sellingProductGroups = response.data.sort((a, b) => ((a[orderByPropertyName] > b[orderByPropertyName]) ? 1 : -1));
      } else {
        //
      }
    },
    async SetSelectFieldOptionsModelDatas(productTypeId) {
      this.selectFieldOptions.colors = [];
      this.selectFieldOptions.storageCapacity = [];
      const response = await this.$store.dispatch('GetDeviceModelDatas', productTypeId);
      if (response.status === this.$HTTP_OK) {
        if (CommonUtils.isNotEmpty(response.data.colors)) {
          this.selectFieldOptions.colors = response.data.colors;
        }
        if (CommonUtils.isNotEmpty(response.data.storageCapacity)) {
          this.selectFieldOptions.storageCapacity = response.data.storageCapacity;
        }
      }
    },
  },
});
