/* eslint-disable no-shadow */

// initial state
const state = {
  loadingAndResponseDialog: {
    errorBtnText: 'button.ok',
    errorText: 'ERROR_MESSAGES.error',
    isVisible: false,
    loadingText: 'LOADING_MESSAGES.loading',
    progress: 0,
    showProgress: false,
    stepper: 1,
    successText: 'SUCCESS_MESSAGES.ok',
    successBtnText: 'button.ok',
    type: 1,
  },
};

// getters
const getters = {
  //
};

// actions
const actions = {
  //
};

// mutations
const mutations = {
  openLARD(state, payload) {
    state.loadingAndResponseDialog.stepper = 1;
    if (payload.type) {
      state.loadingAndResponseDialog.type = payload.type;
    } else {
      state.loadingAndResponseDialog.type = 1;
    }
    if (payload.message) {
      state.loadingAndResponseDialog.loadingText = payload.message;
    } else {
      state.loadingAndResponseDialog.loadingText = 'LOADING_MESSAGES.loading';
    }
    if (payload.showProgress) {
      state.loadingAndResponseDialog.showProgress = payload.showProgress;
    } else {
      state.loadingAndResponseDialog.showProgress = false;
    }
    state.loadingAndResponseDialog.isVisible = true;
  },
  closeLARD(state) {
    state.loadingAndResponseDialog.isVisible = false;
  },
  changeContentToSuccess(state, payload) {
    if (payload.message) {
       state.loadingAndResponseDialog.successText = payload.message;
    } else {
      state.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.ok';
    }
    if (payload.buttonText) {
      state.loadingAndResponseDialog.successBtnText = payload.buttonText;
    } else {
      state.loadingAndResponseDialog.successBtnText = 'button.ok';
    }
    state.loadingAndResponseDialog.stepper = 2;
  },
  changeContentToError(state, payload) {
    if (payload.message) {
      state.loadingAndResponseDialog.errorText = payload.message;
    } else {
      state.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.error';
    }
    if (payload.buttonText) {
      state.loadingAndResponseDialog.errorBtnText = payload.buttonText;
    } else {
      state.loadingAndResponseDialog.errorBtnText = 'button.ok';
    }
    state.loadingAndResponseDialog.stepper = 3;
  },
  setLARDProgress(state, payload) {
    state.loadingAndResponseDialog.progress = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
