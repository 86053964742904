<template>
  <v-dialog v-model="elementProps.isVisible" max-width="340" persistent>
    <v-stepper v-model="elementProps.stepper">
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <v-card>
            <v-card-title class="primary justify-center py-12">
              <template v-if="elementProps.showProgress">
                <v-progress-circular :rotate="-90" size="80" width="6" :value="elementProps.progress" color="white">
                  {{ elementProps.progress }}%
                </v-progress-circular>
              </template>
              <template v-else>
                <v-progress-circular size="80" indeterminate color="white" width="6"></v-progress-circular>
              </template>
            </v-card-title>
            <v-card-text class="px-4 py-10 text-center">
              <h1 class="mb-8">
                {{ $t('working') }}
              </h1>
              <p>
                {{ $t(`${elementProps.loadingText}`) }}
              </p>
            </v-card-text>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-0">
          <v-card>
            <v-card-title class="success justify-center py-12">
              <v-icon size="62" color="white">
                mdi-check-circle-outline
              </v-icon>
            </v-card-title>
            <v-card-text class="px-4 py-10 text-center">
              <h1 class="mb-8">
                {{ $t('yuppee') }}
              </h1>
              <div>
                {{ $t(`${elementProps.successText}`) }}
              </div>
            </v-card-text>
            <v-card-actions class="px-4 pb-4 justify-center">
              <v-btn color="success" @click="onSuccessBtnClick">
                {{ $t(`${elementProps.successBtnText}`) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="3" class="pa-0">
          <v-card>
            <v-card-title class="error justify-center py-12">
              <v-icon size="62" color="white">
                mdi-close-circle-outline
              </v-icon>
            </v-card-title>
            <v-card-text class="px-4 py-10 text-center">
              <h1 class="mb-8">
                {{ $t('ooops') }}
              </h1>
              <div>
                {{ $t(`${elementProps.errorText}`) }}
              </div>
            </v-card-text>
            <v-card-actions class="px-4 pb-4 justify-center">
              <v-btn color="error" @click="onErrorBtnClick">
                {{ $t(`${elementProps.errorBtnText}`) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
export default {
  name: 'LoadingAndResponseDialog',
  components: {
    //
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          errorBtnText: 'errorBtnText',
          errorText: 'Error text',
          isVisible: false,
          loadingText: 'Loading text',
          progress: 0,
          showProgress: false,
          stepper: 1,
          successText: 'Success text',
          successBtnText: 'successBtnText',
        };
      },
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    onSuccessBtnClick() {
      this.$emit('on-success-click');
    },
    onErrorBtnClick() {
      this.$emit('on-error-click');
    },
  },
};
</script>

<style scoped>

</style>
