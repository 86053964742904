<template>
  <v-app>
    <loading-and-response-dialog :element-props="$store.state.lard.loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccessClickInLARDialog"></loading-and-response-dialog>
    <component :is="layout" key="normalLayout">
      <router-view></router-view>
    </component>
  </v-app>
</template>

<script>
import LoadingAndResponseDialog from 'src/components/shared/LoadingAndResponseDialog.vue';
const defaultLayout = 'outer';

export default {
  name: 'App',
  components: {
    LoadingAndResponseDialog,
  },
  async beforeMount() {
    await this.detectLanguage();
  },
  async mounted() {
    this.$store.dispatch('GetSystemInfo', { code: this.$CODE });
  },
  updated() {
    this.setLayout();
  },
  data() {
    return {
      layout: null,
    };
  },
  methods: {
    setLayout() {
      this.layout = `${this.$route.meta.layout || defaultLayout}-layout`;
    },
  },
};

</script>
