const authToken = 'eY$ns#n,@Z)7B';

export const getToken = () => window.sessionStorage.getItem(authToken);

export const saveToken = token => {
  if (!token) {
    return;
  }
  window.sessionStorage.setItem(authToken, token);
};

export const destroyToken = () => {
  window.sessionStorage.removeItem(authToken);
};

export default {
  getToken,
  saveToken,
  destroyToken,
};
