/* eslint-disable no-shadow */

// initial state
import { APIGET, APIPOST } from 'src/utils/apiHelper';
import { saveToken } from 'src/utils/tokenHelper';
import { saveExpTime, saveUserType } from 'src/utils/userHelper';

const state = {
  user: null,
};

// getters
const getters = {
  getExpirationTime(state) {
    return state.user.expirationTime;
  },
  getUserId(state) {
    return state.user.userId;
  },
  getUserRoles(state) {
    return state.user.userRoles;
  },
};

// actions
const actions = {
  async Login(context, credentials) {
    const response = await APIPOST('login', credentials);
    console.log('Login - ', response);
    if (response.status === 200) {
      await saveToken(response.data.data.Token);
      await saveUserType(response.data.data.type);
      await saveExpTime(response.data.data.expirationTime);
      context.commit('setUser', response.data.data);
    }
    return response.data;
  },
  async Logout(context, credentials) {
    window.sessionStorage.clear();
    context.commit("setUser", null);
    return 200;
  },
  async LoginValidator(context, credentials) {
    const response = await APIGET('login/validator', {}, true, 'CRM');
    return response.data.status;
  },
  async CheckStoreUser(context, credentials) {
    if (!context.state.user) {
      // context.commit('setUser', getUserFull());
    }
  },
  async RouteRoleValidator(context, credentials) {
    await context.dispatch('CheckStoreUser');
    if (credentials === 'NOROLE') {
      return true;
    } else {
      return context.state.user.userRoles.some(role => credentials.includes(role));
    }
  },
};

// mutations
const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
