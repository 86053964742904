<template>
  <v-snackbar v-model="snackbarIsVisible"
              right
              app
              top
              light
              color="transparent"
              :timeout="7000">
    <template v-slot:default>
      <v-alert border="top"
               width="100%"
               style="min-height: 48px;"
               colored-border
               :color="color"
               class="ma-0">
        <div class="text-subtitle-1 font-weight-bold">
          {{ title }}
        </div>
        <div v-html="description"></div>
      </v-alert>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'SystemMessageSnackbar',
  components: {
    //
  },
  props: {
    color: {
      type: String,
      default: 'secondary',
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '<p>This is the Title</p>',
    },
    description: {
      type: String,
      default: '<p>This is the Description!</p>',
    },
  },
  data() {
    return {
      snackbarIsVisible: false,
    };
  },
  methods: {
    //
  },
  watch: {
    isVisible(value) {
      this.snackbarIsVisible = value;
    },
    snackbarIsVisible(value) {
      this.$emit('update:is-visible', value);
    }
  },
};
</script>

<style scoped>

</style>
