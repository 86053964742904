/* eslint-disable no-shadow */
import router from 'src/router/router';
// initial state
const state = {
  navbar: [
    {
      text: 'navigation.myDashboard',
      route: {
        external: false,
        path: '',
        name: 'MyDashboard',
      },
      click: () => {
        router.push({ name: 'MyDashboard' })
      },
    },
  ],
};

// getters
const getters = {
  //
};

// actions
const actions = {
  //
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
