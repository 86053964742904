import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import appStore from 'src/store/appStore.js';
import auth from 'src/store/auth';
import deviceStore from 'src/store/deviceStore.js';
import innerNavigation from 'src/store/innerNavigation';
import lard from 'src/store/lard.js';
import outerNavigation from 'src/store/outerNavigation.js';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createLogger()],
  modules: {
    appStore,
    auth,
    deviceStore,
    innerNavigation,
    lard,
    outerNavigation,
  },
});
