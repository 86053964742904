import { saveCalcData, saveDamages } from 'src/utils/calcDataHelper.js';
import Vue from 'vue';

export default Vue.mixin({
  data() {
    return {
      campaignForm: false,
      damages: {},
      deviceForm: false,
      deviceSelector: {
        brand: null,
        productTypeFull: null,
        productType: {},
      },
      deviceState: null,
      deviceStatus: null,
      deviceToSell: {
        brandName: '',
        colorId: null,
        customerSellingPriceFrom: 0,
        customerSellingPriceTo: 0,
        deviceIdentifier: '',
        deviceImage: null,
        deviceName: '',
        productTypeId: '',
        storageCapacityId: null,
      },
      imeiForm: false,
      isFinished: false,
      searching: false,
      luhnCheckStatus: null,
      productGroupForm: false,
      searchInputBrand: '',
      searchInputProductType: '',
      selectedCampaign: null,
      selectedProductGroup: {},
      selectedProductGroupId: 1,
      selectedSellingProductGroup: {},
      sellingProductTypesLoading: false,
    };
  },
  methods: {
    async callGetDeviceByImei(imei) {
      this.openLARDialog();
      this.searching = true;
      const deviceResponse = await this.$store.dispatch('GetDeviceByImei', imei);
      console.log('deviceResponse: ', deviceResponse);
      this.deviceStatus = deviceResponse.status;
      if (deviceResponse.status === this.$HTTP_OK) {
        this.deviceToSell.brandName = deviceResponse.data.brandName;
        this.deviceToSell.deviceName = deviceResponse.data.deviceName;
        this.deviceToSell.productTypeId = deviceResponse.data.productTypeId;
        this.selectedProductGroupId = deviceResponse.data.productGroupId;
        await this.callGetDeviceState();
        this.scrollToPosition('deviceState');
      } else {
        this.deviceToSell = {
          brandName: '',
          colorId: null,
          customerSellingPriceFrom: 0,
          customerSellingPriceTo: 0,
          deviceIdentifier: imei,
          deviceImage: null,
          deviceName: '',
          productTypeId: '',
          storageCapacityId: null,
        };
        if (this.$store.state.appStore.appSelectedPartner.multiple_product_group_enabled === 1) {
          await this.SetSelectFieldOptionsBrandsByProductGroupId(this.selectedProductGroup.productGroupId);
        } else {
          await this.SetSelectFieldOptionsBrands();
        }
        // this.scrollToPosition('deviceToSellNoResult');
        this.stepper = 2;
        this.sliderContent.title2 = this.$NO_IMIE_RESULT_TITLE;
        this.sliderContent.subTitle2 = this.$NO_IMIE_RESULT_SUBTITLE;
      }
      this.searching = false;
      this.isFinished = true;
      this.closeLARDialog();
    },
    async callGetDeviceState() {
      const avsAndPcdid = await this.$store.dispatch('FindAppPartnerCampaignDataId',
        {
          // partner_name: this.$route.params.partnerName,
          partner_name: this.$store.state.appStore.appSelectedPartner.url,
          bb_campaign_id: this.selectedCampaign.bb_campaign_id,
        }
      );
      this.deviceState = await this.$store.dispatch('GetDeviceState',
        { avs: avsAndPcdid.avs, productGroupId: this.selectedProductGroupId });
      for (let i = 0; i < this.deviceState.data.length; i++) {
        let typeName = this.deviceState.data[i].typeName;
        console.log(i);
        console.log('typeName: ', typeName);
        for (let j = 0; j < this.deviceState.data[i].data.length; j++) {
          console.log(j);
          this.deviceState.data[i].data[j].damageStatusName = this.deviceState.data[i].data[j].statusName;
          this.deviceState.data[i].data[j].damageTypeName = typeName;
          console.log('this.deviceState.data[i].data[j]: ', this.deviceState.data[i].data[j]);
        }
      }
    },
    async onDeviceSelectorNextButtonClick() {
      this.openLARDialog();
      if (this.deviceStatus !== this.$HTTP_OK) {
        this.deviceToSell.brandName = this.deviceSelector.brand.name;
        this.deviceToSell.deviceName = this.deviceSelector.productTypeFull.deviceName;
        this.deviceToSell.productTypeId = this.deviceSelector.productTypeFull.productTypeId;
      }
      this.selectedProductGroupId = this.deviceSelector.productTypeFull.productGroupId;
      await this.callGetDeviceState();
      if (this.deviceState.status === this.$HTTP_OK) {
        this.scrollToPosition('deviceState');
        this.closeLARDialog();
      } else {
        this.changeLARDialogContentToError();
      }
    },
    onProductGroupSelectorChange() {
      this.deviceState = null;
      this.deviceStatus = null;
      this.isFinished = false;
      this.deviceSelector = {
        brand: null,
        productTypeFull: null,
        productType: {},
      };
      this.deviceToSell = {
        brandName: '',
        colorId: null,
        customerSellingPriceFrom: 0,
        customerSellingPriceTo: 0,
        deviceIdentifier: '',
        deviceImage: null,
        deviceName: '',
        productTypeId: '',
        storageCapacityId: null,
      };
    },
    async onProductGroupSelectorNextButtonClick() {
      this.openLARDialog();
      this.deviceStatus = this.$HTTP_MY_ERROR;
      this.isFinished = true;
      await this.SetSelectFieldOptionsBrandsByProductGroupId(this.selectedProductGroup.productGroupId);
      this.scrollToPosition('devicePicker');
      this.closeLARDialog();
    },
    async onBrandInputChange() {
      if (this.$store.state.appStore.appSelectedPartner.multiple_product_group_enabled === 1) {
        await this.SetSelectFieldOptionsProductTypesByBrandIdAndProductGroupId(this.deviceSelector.brand.id, this.selectedProductGroup.productGroupId);
      } else {
        await this.SetSelectFieldOptionsProductTypesByBrandId(this.deviceSelector.brand.id)
      }
    },
    async onSellingProductGroupSelectorChange(item) {
      this.sellingProductTypesLoading = true;
      await this.$store.dispatch('GetSellingProductTypes', item.productGroupId);
      this.sellingProductTypesLoading = false;
    },
    resetDeviceFinder() {
      this.deviceStatus = null;
      this.isFinished = false;
      this.searching = false;
      this.luhnCheckStatus = null;
    },
    async callCreateNewCalcData() {
      this.openLARDialog();
      const damageArray = Object.values(this.damages);
      let damageToSend = [];
      let damageToSave = [];
      damageArray.forEach((item) => {
        damageToSend.push({ typeId: item.typeId, statusId: item.statusId });
      });
      damageArray.forEach((item) => {
        damageToSave.push(item);
      });
      console.log('%cdamageArray', 'background: aqua; color: black; font-size: 14px;');
      console.table(damageToSend);
      const avsAndPcdid = await this.$store.dispatch('FindAppPartnerCampaignDataId',
        {
          // partner_name: this.$route.params.partnerName,
          partner_name: this.$store.state.appStore.appSelectedPartner.url,
          bb_campaign_id: this.selectedCampaign.bb_campaign_id,
        }
      );
      let calcDataToSend = {
        availableServiceId: avsAndPcdid.avs,
        bbSellingProductTypeId: this.selectedCampaign.bb_selling_product_type_id || null,
        brandName: this.deviceToSell.brandName,
        colorId: this.deviceToSell.colorId,
        customerSellingPriceFrom: this.deviceToSell.customerSellingPriceFrom,
        customerSellingPriceTo: this.deviceToSell.customerSellingPriceTo,
        customerType: this.$CUSTOMER_TYPE,
        damage: damageToSend,
        imei: this.deviceToSell.deviceIdentifier,
        partnerCampaignDataId: avsAndPcdid.pcdid,
        productTypeId: this.deviceToSell.productTypeId,
        productTypeName: this.deviceToSell.deviceName,
        storageCapacityId: this.deviceToSell.storageCapacityId,
      }
      console.log('%cCalcDataToSend', 'background: lime; color: black; font-size: 14px;');
      console.log('calcDataToSend', calcDataToSend);
      const response = await this.$store.dispatch('CreateNewCalcData', calcDataToSend);
      if (response.status === this.$HTTP_OK) {
        response.data.brandName = this.deviceToSell.brandName;
        response.data.deviceName = this.deviceToSell.deviceName;
        response.data.damages = damageToSave;
        if (response.data.imei.length === 0) {
          response.data.imei = -1;
        }
        await saveCalcData(response);
        this.closeLARDialog();
        // this.$router.push({ name: `${this.$route.meta.bbType}SzemelyesAdatok`});
        this.$router.push({ name: `${this.$store.state.appStore.appSelectedPartner.campaign_type_code_lower}SzemelyesAdatok`});
      } else if (response.status === this.$HTTP_MY_ERROR){
        this.changeLARDialogContentToError(response.message);
      } else {
        this.changeLARDialogContentToError();
      }
    },
    scrollToPosition(DOMid) {
      this.$vuetify.goTo(`#${DOMid}`, this.$SCROLL_OPTIONS);
    },
  },
});
