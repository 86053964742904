import Vue from 'vue';
import {
  HEREGETPOSTALCODESBYCOUNTRY,
  HEREGETCITYBYPOSTALCODE,
  HEREGETSTREETBYPOSTALCODE
} from 'src/utils/hereAPI.js';

export default Vue.mixin({
  data() {
    return {
      addressSelect: {
        cities: [
          { header: 'Begépeléssel rá tud keresni a városra' },
        ],
        countries: [
          { label: 'Magyarország', countryCode: 'HUN' },
        ],
        postalCodes: [
          { header: 'Begépeléssel rá tud keresni az irányítószámra' },
        ],
        streets: [
          { header: 'Begépeléssel rá tud keresni az utcára' },
        ],
      },
      citySearch: '',
      postalCodeSearch: '',
      streetSearch: '',
      streetTemp: '',
    };
  },
  methods: {
    async getPostalCodesByCountry(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.preOfferRequest.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.addressSelect.postalCodes = response.data;
        this.addressSelect.postalCodes.unshift({ header: this.$t('hints.startTypingPostalCode') });
      }
    },
    async getCityByPostalCode() {
      let postalcode = 0;
      if (this.preOfferRequest.deliveryInAddress.postalCode && this.preOfferRequest.deliveryInAddress.postalCode.address) {
        postalcode = this.preOfferRequest.deliveryInAddress.postalCode.address.postalCode;
        this.preOfferRequest.deliveryInAddress.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.preOfferRequest.deliveryInAddress.postalCode, this.preOfferRequest.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.addressSelect.cities = response.data;
        this.addressSelect.cities.unshift({ header: this.$t('hints.startTypingCity') });
      }
    },
    async getStreetByPostalCode(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.preOfferRequest.deliveryInAddress.postalCode, this.preOfferRequest.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.addressSelect.streets = response.data;
        this.addressSelect.streets.unshift({ header: this.$t('hints.startTypingStreet') });
      }
    },
    async onPostalCodeSelect(type= 'NEWSEARCH') {
      await this.getCityByPostalCode();
      if (this.addressSelect.cities.length === 2) {
        this.preOfferRequest.deliveryInAddress.city = this.addressSelect.cities[1].address.city;
        this.citySearch = this.addressSelect.cities[1].address.city;
      }
      if (type === 'NEWSEARCH') {
        this.preOfferRequest.deliveryInAddress.publicPlaceName = '';
        this.preOfferRequest.deliveryInAddress.publicPlaceType = '';
        this.preOfferRequest.deliveryInAddress.streetNumber = '';
        this.streetIdeiglenes = '';
        this.streetSearch = null;
      }
    },
    onPublicPlaceSelect() {
      let street = '';
      let type = '';
      if (this.streetTemp && this.streetTemp.length > 0) {
        street = this.streetTemp.substring(0, this.streetTemp.lastIndexOf(' '));
        type = this.streetTemp.substring(this.streetTemp.lastIndexOf(' '), this.streetTemp.length + 1).trim();
      } else if (this.streetTemp && this.streetTemp.address) {
        street = this.streetTemp.address.street.substring(0, this.streetTemp.address.street.lastIndexOf(' '));
        type = this.streetTemp.address.street.substring(this.streetTemp.address.street.lastIndexOf(' '), this.streetTemp.address.street.length + 1).trim();
      }
      this.preOfferRequest.deliveryInAddress.publicPlaceName = street;
      this.preOfferRequest.deliveryInAddress.publicPlaceType = type;
    },
    clearAllAddressInputs() {
      this.postalCodeSearch = '';
      this.preOfferRequest.deliveryInAddress.publicPlaceName = '';
      this.preOfferRequest.deliveryInAddress.publicPlaceType = '';
      this.preOfferRequest.deliveryInAddress.streetNumber = '';
      this.citySearch = '';
      this.streetSearch = '';
      this.streetTemp = '';
      this.streetIdeiglenes = '';
      this.streetSearch = null;
      this.addressSelect.postalCodes = [
        { header: 'Begépeléssel rá tud keresni az irányítószámra' },
      ];
      this.addressSelect.cities = [
        { header: 'Begépeléssel rá tud keresni a városra' },
      ];
      this.addressSelect.streets = [
        { header: 'Begépeléssel rá tud keresni az utcára' },
      ];
    },
  },
  watch: {
    postalCodeSearch: async function(value) {
      await this.getPostalCodesByCountry(value);
    },
    streetSearch: async function(value) {
      await this.getStreetByPostalCode(value);
    },
    'address.isCompany': function (value) {
      // this.onCompanySelect(value);
      // this.isCompanyDialog = value === 1;
    },
  },
});
