import Vue from 'vue';

export default Vue.mixin({
  data() {
    return {
      calcData: null,
      calcDataByHash: null,
      checkboxes: {
        gdpr: false,
        termsAndCond: false,
        marketing: false,
      },
      contractDownloadResponse: null,
      customerForm: {
        customer: {
          id: null,
          customerType: this.$CUSTOMER_TYPE,
          crmNamePrefixId: null,
          firstName: '',
          middleName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
        },
        addition: {
          identifierCardTypeId: null,
          identifierCardNumber: null,
        },
        buybackAddress: {
          id: null,
          isCompany: 0,
          country: {
            label: 'Magyarország',
            countryCode: 'HUN',
          },
          city: 'Budaörs',
          postalCode: '2040',
          publicPlaceName: 'Kinizsi',
          publicPlaceType: 'utca',
          streetNumber: '2/b',
          other: '',
          label: '',
          billingName: '',
          taxNumber: '',
          accountNumber: '',
        },
        calcDataId: null,
      },
      isCustomerForm: false,
      preOfferRequest: {
        hash: '',
        deliveryInAddress: {
          id: null,
          isCompany: 0,
          country: {
            label: 'Magyarország',
            countryCode: 'HUN'
          },
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: '',
          label: '',
          billingName: '',
          taxNumber: '',
          accountNumber: '',
        },
      },
    };
  },
  methods: {
    async callAcceptBuybackFinalOffer() {
      this.openLARDialog('LOADING_MESSAGES.acceptFinaloffer', this.$GO_TO_THANKS);
      const response = await this.$store.dispatch('AcceptBuybackFinalOffer',
        { hash: this.$route.params.identicalHash, statusId: 80 });
      if (response.status === this.$HTTP_OK) {
        await this.$router.push({ name: 'arajanlatElfogadasaSikeres'});
        this.closeLARDialog();
      } else {
        this.changeLARDialogContentToError(response.message);
      }
    },
    async callAcceptBuybackPreOffer() {
      this.openLARDialog('LOADING_MESSAGES.acceptPreoffer', this.$GO_TO_HOME);
      let idCardResponse = null;
      if (this.calcDataByHash.data.identifierCardNumber.length < 2) {
        idCardResponse = await this.$store.dispatch('UpdateCustomerIdentifierData',
          {
            customerId: this.calcDataByHash.data.customerId,
            body: {
              identifierCardTypeId: this.customerForm.addition.identifierCardTypeId,
              identifierCardTypeNumber: this.customerForm.addition.identifierCardNumber,
            },
            hash: this.preOfferRequest.hash,
          });
        console.log(idCardResponse);
      }
      if (idCardResponse && ![this.$HTTP_CREATED, this.$HTTP_OK].includes(idCardResponse.status)) {
        this.changeLARDialogContentToError(idCardResponse.message);
      } else {
        let dataToSend = JSON.parse(JSON.stringify(this.preOfferRequest));
        dataToSend.deliveryInAddress.isCompany = this.preOfferRequest.deliveryInAddress.isCompany ? 1 : 0;
        const response = await this.$store.dispatch('AcceptBuybackPreOffer', dataToSend);
        if (response.status === this.$HTTP_CREATED) {
          this.changeLARDialogContentToSuccess('SUCCESS_MESSAGES.acceptPreoffer');
        } else {
          this.changeLARDialogContentToError(response.message);
        }
      }
    },
    async callCreateCustomerDataToCalcData() {
      console.log('callCreateCustomerDataToCalcData', this.customerForm);
      this.openLARDialog('LOADING_MESSAGES.loading', this.$GO_TO_HOME);
      if (this.$store.state.appStore.appSelectedPartner.customer_identity_data_first_page === 0) {
        this.customerForm.addition.identifierCardNumber = '';
        this.customerForm.addition.identifierCardTypeId = 1;
      }
      const response = await this.$store.dispatch('CreateCustomerDataToCalcData', this.customerForm);
      if (response.status === this.$HTTP_CREATED) {
        this.changeLARDialogContentToSuccess('SUCCESS_MESSAGES.finalMessageDialog');
      } else {
        this.changeLARDialogContentToError();
      }
    },
    async callDownloadBuybackContractByHash() {
      this.openLARDialog();
      const response = await this.$store.dispatch('DownloadBuybackContractByHash', this.$route.params.identicalHash);
      this.contractDownloadResponse = response;
      if (response === this.$HTTP_OK) {
        this.closeLARDialog();
      } else {
        this.changeLARDialogContentToError();
      }
    },
    async callGetCalcDataByHash(hash) {
      const response = await this.$store.dispatch('GetCalcDataByHash', hash);
      console.log('bbOffer - ', response);
      this.calcDataByHash = response;
      this.preOfferRequest.hash = hash;
    },
    copyCustomerNameToBillingName(source = 'CUSTOMERFORM') {
      if (source === 'CUSTOMERFORM') {
        if (this.customerForm.customer.lastName.length > 0 && this.customerForm.customer.firstName.length > 0) {
          this.customerForm.buybackAddress.billingName = `${this.customerForm.customer.lastName} ${this.customerForm.customer.firstName}`;
        }
      } else {
        this.preOfferRequest.deliveryInAddress.billingName = `${this.calcDataByHash.data.customerLastName} ${this.calcDataByHash.data.customerFirstName}`;
      }
    },
  },
});
