import Vue from 'vue';
import dayjs from 'dayjs';

export default Vue.mixin({
  data() {
    return {
      inputRules: {
        codeValidatorLowerCase: (value) => /^[a-z0-9-]*$/.test(value) || 'Csak ékezet nélküli kisbetűket, számokat és kötőjelet tartalmazhat!',
        codeValidatorUpperCase: (value) => /^[A-Z0-9-_]*$/.test(value) || 'Csak ékezet nélküli nagybetűket, számokat és kötőjelet és alulvonást tartalmazhat!',
        emailValidator: (email) => /.+@.+\..+/.test(email) || 'A helyes formátum: minta@domain.hu',
        imeiRule: [
          (value) => !!value || this.$t('hints.imeiHintRequired'),
          (value) => /^[0-9]*$/.test(value) || this.$t('hints.onlyNumbers'),
          (value) => (value && value.length === 15) || this.$t('hints.minLenght15'),
        ],
        maxFileSizeOptional: (value) => !value || value.size < 5000000 || this.$t('hints.maxFileSizeRule'),
        min3Length: (value) => (value && value.length > 2) || this.$t('hints.minLenght3'),
        noSpaceValidator: (value) => !(/\s/.test(value)) || 'Nem tartalmazhat szőközt!',
        numberValidator: (value) => /^[0-9]*$/.test(value) || 'Csak számokat írjon!',
        phoneValidator: (value) => /^[0-9-]*$/.test(value) || 'Csak számokat írjon!',
        requiredField: (value) => !!value || this.$t('hints.requiredField'),
        selectRule: (value) => (!!value) || this.$t('hints.requiredField'),
        simpleDateRule: (value) => this.simpleDateIsOK(value) || `${this.$t('hints.simpleDateReqMin')} ${this.simpleMinDate}!`,
        simpleDateRuleOptional: (value) => this.simpleDateIsOK(value) || `${this.$t('hints.simpleDateReqMinOptional')} ${this.simpleMinDate}`,
        snRule: [
          (value) => !!value || this.$t('hints.requiredField'),
          (value) => /^[a-zA-Z0-9-/\\]*$/.test(value) || this.$t('hints.snHint'),
          (value) => (value && value.length < 23) || this.$t('hints.maxLenght22'),
        ],
        taxRules: [
          (value) => !!value || 'Kötelező kitölteni',
          (value) => (value && value.length === 13) || 'Kötelező kitölteni',
        ],
      },
      simpleMinDate: '1970. 01. 01.',
    };
  },
  methods: {
    simpleDateIsOK(date) {
      const jsDate = new Date(date);
      const parse = Date.parse(date);
      if (parse) {
        // console.log('OK');
      } else {
        // console.log('NEM OK');
      }
      const inputDate = dayjs(jsDate);
      const minD = dayjs(this.simpleMinDate);
      if (date) {
        if (date.length === 10) {
          return  inputDate.isAfter(minD);
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
});
