import Vue from 'vue';
import { version } from '../../package.json';

Vue.prototype.$HTTP_OK = 200;
Vue.prototype.$HTTP_CREATED = 201;
Vue.prototype.$HTTP_ACCEPTED = 202;
Vue.prototype.$HTTP_DELETED = 204;
Vue.prototype.$HTTP_NOT_FOUND = 404;
Vue.prototype.$HTTP_MY_ERROR = 422;

Vue.prototype.$VERSION_NO = version;

Vue.prototype.$CODE = 'MEGVESSZUKAMOBILODHU';

Vue.prototype.$BB_AVS = 118;
Vue.prototype.$ND_PAGE_TITLE = 'MegvesszükAMobilod Trade-in';

Vue.prototype.$PRIMARY_COLOR = '#7F51D8';
Vue.prototype.$SECONDARY_COLOR = '#3E90EA';

Vue.prototype.$SHOW_NAV_INFO_BUTTON = true;

Vue.prototype.$SCROLL_OPTIONS = {
  duration: 1200,
  easing: 'easeOutQuart',
  offset: 2,
};

Vue.prototype.$CUSTOMER_TYPE = 'BUYBACK_TMX';

Vue.prototype.$GO_TO_HOME = 'GO_TO_HOME';
Vue.prototype.$GO_TO_THANKS = 'GO_TO_THANKS';
Vue.prototype.$RELOAD_PAGE = 'RELOAD_PAGE';
Vue.prototype.$RELOAD_COMPONENT = 'RELOAD_COMPONENT';

Vue.prototype.$PRIVACY_POLICY = 'doc/adatvedelmitajekoztatomam20220829.pdf';
Vue.prototype.$TERMS_AND_CONDS = 'doc/tmxaszfbuybackmam0306.pdf';
Vue.prototype.$COMPLAINT_HANDLING_POLICY = 'doc/panaszkezelesiszabalyzatmam20220915.pdf';

Vue.prototype.$SLIDER_DESC_DEFAULT = 'Számíttasd be régi készülékedet a promóció időtartama alatt! Galaxy Z Fold3 5G '
  + 'vásárlásod során felhasználható 150.000 Ft értékű, illetve Galaxy Z Flip3 5G vásárlásod során felhasználható '
  + '100.000 Ft értékű kupont küldünk számodra, vagy ezt az összeget hozzáadjuk a régi készüléked vételárához!';
Vue.prototype.$SLIDER_DESC_CASHBACK = 'Számíttasd be régi készülékedet a promóció időtartama alatt, '
  + 'és Galaxy A33 vásárlása esetén extra 10.000 Ft-ot adunk a kiszámított vételáron felül régi készülékedért!';
Vue.prototype.$SLIDER_DESC_VOUCHER = 'Számíttasd be régi készülékedet a promóció időtartama alatt, '
  + 'és Galaxy A33 vásárlásod során felhasználható 10.000 Ft értékű kupont küldünk számodra!';
Vue.prototype.$NO_IMIE_RESULT_TITLE = 'Sajnos rendszerük nem tudta beazonosítani készüléked, ezért kérlek az '
  + 'alábbi legördülő menüből, válaszd ki készüléked gyártóját és pontos típusát.';
Vue.prototype.$NO_IMIE_RESULT_SUBTITLE = 'Amennyiben a legördülő menüben nem találod meg készüléked gyártóját '
  + 'és/vagy típusát, akkor az adott készüléket nincs módunkban megvásárolni.';
