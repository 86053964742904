<template>
  <div v-frag>
    <inner-nav-bar />
    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import InnerNavBar from 'src/components/navigation/InnerNavBar';

export default {
  name: 'InnerLayout',
  components: {
    InnerNavBar,
  },
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
