import Vue from 'vue';

export default Vue.mixin({
  data() {
    return {
      //
    };
  },
  methods: {
    openLARDialog(message, type, showProgress) {
      this.$store.commit('openLARD', { message: message, type: type, showProgress: showProgress });
    },
    closeLARDialog() {
      this.$store.commit('closeLARD');
    },
    changeLARDialogContentToSuccess(message, buttonText) {
      this.$store.commit('changeContentToSuccess', { message: message, buttonText: buttonText });
    },
    changeLARDialogContentToError(message, buttonText) {
      this.$store.commit('changeContentToError', { message: message, buttonText: buttonText });
    },
    onSuccessClickInLARDialog() {
      // a type alapján lehet különböző a bezárási logika
      this.closeLARDialog();
      switch (this.$store.state.lard.loadingAndResponseDialog.type) {
        case this.$RELOAD_PAGE:
          window.location.reload();
          break;
        case this.$RELOAD_COMPONENT:
          this.$store.commit('forceRerender');
          break;
        case this.$GO_TO_THANKS:
          this.$router.push({ name: 'arajanlatElfogadasaSikeres' });
          break;
        case this.$GO_TO_HOME:
          this.$router.push({ name: 'Home' });
          window.sessionStorage.clear();
          break;
      }
    },
    onErrorClickInLARDialog() {
      // a type alapján lehet különböző a bezárási logika
      this.closeLARDialog();
    },
    setLARDialogProgress(progress) {
      this.$store.commit('setLARDProgress', progress);
    },
  },
})
