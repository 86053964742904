import axios from 'axios';
import i18n from '../i18n';

const APP_ID = 'ANPkpMcEMgzawvK6KVFt';
const APP_CODE = 'e1lH4iNdjTlSsRK7gvZTSw';
const BASE_URL = `https://autocomplete.geocoder.api.here.com/6.2/suggest.json?app_id=${APP_ID}&app_code=${APP_CODE}`;

const hereapi = axios.create({
  baseURL: BASE_URL,
  timeout: 20000,
});

export async function HEREGETCOUNTRY(country, lang = i18n.locale.substring(0, 2)) {
  try {
    const response = await hereapi({
      method: 'get',
      url: `${BASE_URL}&maxresults=20&query=${country}&language=${lang}&resultType=country`,
      headers: {},
    });
    if (response.data.suggestions && response.data.suggestions.length > 0) {
      const filteredList = response.data.suggestions.filter(item => item.matchLevel === 'country');
      return { error: false, data: filteredList };
    }
    return { error: true, message: 'noData' };
  } catch (err) {
    return { error: true, message: err };
  }
}

export async function HEREGETCITYBYCOUNTRY(city, countryCode, lang = i18n.locale.substring(0, 2)) {
  try {
    const response = await hereapi({
      method: 'get',
      url: `${BASE_URL}&query=${city}&language=${lang}&country=${countryCode}&maxresults=20&resultType=city`,
      headers: {},
    });
    if (response.data.suggestions && response.data.suggestions.length > 0) {
      const filteredList = response.data.suggestions.filter(item => item.matchLevel === 'city');
      return { error: false, data: filteredList };
    }
    return { error: true, message: 'noData' };
  } catch (err) {
    return { error: true, message: err };
  }
}

export async function HEREGETPOSTALCODESBYCOUNTRY(postalCode, countryCode, lang = i18n.locale.substring(0, 2)) {
  try {
    const response = await hereapi({
      method: 'get',
      url: `${BASE_URL}&query=${postalCode}&language=${lang}&resultType=postalcode&country=${countryCode}`,
      headers: {},
    });
    if (response.data.suggestions && response.data.suggestions.length > 0) {
      const filteredList = response.data.suggestions.filter(item => item.matchLevel === 'postalCode');
      return { error: false, data: filteredList };
    }
    return { error: true, message: 'noData' };
  } catch (err) {
    return { error: true, message: err };
  }
}

export async function HEREGETCITYBYPOSTALCODE(postalCode, countryCode, lang = i18n.locale.substring(0, 2)) {
  try {
    const response = await hereapi({
      method: 'get',
      url: `${BASE_URL}&query=${postalCode}&language=${lang}&resultType=postalcode&country=${countryCode}`,
      headers: {},
    });
    if (response.data.suggestions && response.data.suggestions.length > 0) {
      const filteredList = response.data.suggestions.filter(item => item.matchLevel === 'postalCode' && item.address.city);
      return { error: false, data: filteredList };
    }
    return { error: true, message: 'noData' };
  } catch (err) {
    return { error: true, message: err };
  }
}

export async function HEREGETSTREETBYPOSTALCODE(street, postalCode, countryCode, lang = i18n.locale.substring(0, 2)) {
  try {
    const response = await hereapi({
      method: 'get',
      url: `${BASE_URL}&query=${postalCode} ${street}&language=${lang}&maxresults=20&country=${countryCode}`,
      headers: {},
    });
    if (response.data.suggestions && response.data.suggestions.length > 0) {
      const filteredList = response.data.suggestions.filter(item => item.matchLevel === 'street' && item.address.postalCode === postalCode);
      return { error: false, data: filteredList };
    }
    return { error: true, message: 'noData' };
  } catch (err) {
    return { error: true, message: err };
  }
}

export async function HEREGETPOSTALCODESBYCITYLIVE(postalCode, city, countryCode, lang = i18n.locale.substring(0, 2)) {
  try {
    const response = await hereapi({
      method: 'get',
      url: `${BASE_URL}&query=${postalCode}&language=${lang}&maxresults=20&resultType=postalCode&country=${countryCode}`,
      headers: {},
    });
    if (response.data.suggestions && response.data.suggestions.length > 0) {
      const filteredList = response.data.suggestions.filter(item => item.matchLevel === 'postalCode' && item.address.postalCode.includes(postalCode) && item.address.city === city);
      return { error: false, data: filteredList };
    }
    return { error: true, message: 'noData' };
  } catch (err) {
    return { error: true, message: err };
  }
}

export async function HEREGETPOSTALCODESBYCITY(city, countryCode, lang = i18n.locale.substring(0, 2)) {
  try {
    const response = await hereapi({
      method: 'get',
      url: `${BASE_URL}&query=${city}&language=${lang}&maxresults=200&country=${countryCode}`,
      headers: {},
    });
    if (response.data.suggestions && response.data.suggestions.length > 0) {
      const filteredList = response.data.suggestions.filter(item => (item.matchLevel === 'city' || item.matchLevel === 'district') && item.address.city === city);
      return { error: false, data: filteredList };
    }
    return { error: true, message: 'noData' };
  } catch (err) {
    return { error: true, message: err };
  }
}
