/* eslint-disable no-shadow */

// initial state
import Vue from 'vue';
import { APIGET, APIPOST, APIPATCH, APIFILEDOWNLOAD } from 'src/utils/apiHelper.js';
import appConfig from 'src/config/appConfig.js';
import { saveCalcData } from 'src/utils/calcDataHelper.js';

const state = {
  appCampaigns: [],
  appPartners: [],
  appStore: null,
  appSelectedPartner: null,
  appSystemInfo: null,
};

// getters
const getters = {
  getAppCampaigns(state) {
    return state.appCampaigns;
  },
  getAppCampaignsLength(state) {
    return state.appCampaigns.length;
  },
  getAppPartners(state) {
    return state.appPartners;
  },
  getAppPartnersLength(state) {
    return state.appPartners.length;
  },
  getAppSystemInfoContent(state) {
    if (state.appSystemInfo) {
      return {
        title: state.appSystemInfo.data.title,
        description: state.appSystemInfo.data.description,
      };
    }
    return {
      title: 'Rendszerüzenet nem található',
      description: '',
    };
  },
  getAppSystemInfoCurrentVersion(state) {
    if (state.appSystemInfo) {
      return state.appSystemInfo.data.currentVersion;
    }
    return `v${Vue.prototype.$VERSION_NO}`;
  },
};

// actions
const actions = {
  async initApp(context, credentials) {
    const response = await APIGET(`buyback/partner-campaigns?availableServiceId=${Vue.prototype.$BB_AVS}`, {}, true, 'CRM');
    if (response.data.status === Vue.prototype.$HTTP_OK) {
      context.commit('setAppStore', response.data.data);
      context.commit('setAppPartners', response.data.data);
      if (context.state.appPartners.length === 1 && context.state.appSelectedPartner.selling_product_group_enabled === 1) {
        //
      } else {
        context.commit('setAppCampaigns', response.data.data);
      }
    }
    return response.status;
  },
  async AcceptBuybackFinalOffer(context, credentials) {
    const reponse = await APIPATCH(`buyback/calculation-data/${credentials.hash}/next-status`, { statusId: credentials.statusId }, true, 'CRM');
    return reponse.data;
  },
  async AcceptBuybackPreOffer(context, credentials) {
    const response = await APIPOST('buyback/accept-pre-offer', credentials, true, 'CRM');
    return response.data;
  },
  async CreateNewCalcData(context, credentials) {
    const response = await APIPOST(`buyback/calculation/partner-campaign-data/${credentials.partnerCampaignDataId}`, credentials, true, 'CRM');
    // await saveCalcData(response.data);
    return response.data;
  },
  async CreateCustomerDataToCalcData(context, credentials) {
    const response = await APIPOST('buyback/customer-data', credentials, true, 'CRM');
    return response.data;
  },
  async DownloadBuybackContractByHash(context, credentials) {
    const response = await APIFILEDOWNLOAD(`buyback/contracts-sale/${credentials}`, {}, true,
      'CRM', 'get', 'OPEN');
    return response;
  },
  async GetCalcDataByHash(context, credentials) {
    const response = await APIGET(`buyback/calculation-data/${credentials}`, {}, true, 'CRM');
    return response.data;
  },
  async FindAppPartnerCampaignDataId(context, credentials) {
    const selectedPartner = context.state.appPartners.find((item) => item.url === credentials.partner_name);
    console.log('selectedPartner - ', selectedPartner);
    if (selectedPartner.selling_product_group_enabled === 0) {
      const appPartnerCampaignDataId = context.state.appStore.find(
        (item) => item.crm_customer_partner_data_id === selectedPartner.crm_customer_partner_data_id && item.bb_campaign_id === credentials.bb_campaign_id
      );
      console.log('appPartnerCampaignDataId: ', appPartnerCampaignDataId);
      return { pcdid: appPartnerCampaignDataId.id, avs: appPartnerCampaignDataId.available_service_id };
    }
    return { pcdid: credentials.bb_campaign_id, avs: Vue.prototype.$BB_AVS };
  },
  async FindAppSelectedPartner(context, credentials) {
    const selectedPartner = context.state.appPartners.find((item) => item.url === credentials);
    console.log('selectedPartner - ', selectedPartner);
    context.commit('setAppSelectedPartner', selectedPartner);
  },
  async GetCustomerIdentifierCardTypes(context, credentials) {
    const response = await APIGET('buyback/customer-identifier-card-type', {}, true, 'CRM');
    return response.data;
  },
  async GetSellingProductGroups(context, credentials) {
    const response = await APIGET(`buyback/selling-product-groups?availableServiceId=${Vue.prototype.$BB_AVS}`,
      {},
      true,
      'CRM'
    );
    return response.data;
  },
  async GetSellingProductTypes(context, credentials) {
    const response = await APIGET(`buyback/selling-product-types?availableServiceId=${Vue.prototype.$BB_AVS}&productGroupId=${credentials}`,
      {},
      true,
      'CRM'
    );
    if (response.data.status === Vue.prototype.$HTTP_OK) {
      context.commit('setSellingProductGroupAppCampaigns', response.data.data);
    }
    return response.status;
  },
  async UpdateCustomerIdentifierData(context, credentials) {
    const response = await APIPOST(`buyback/customers/${credentials.customerId}/addition-data/${credentials.hash}`,
      credentials.body,
      true,
      'CRM'
    );
    return response.data;
  },
  async GetSystemInfo(context, credentials) {
    const response = await APIGET(`system-info/messages/${credentials.code}`, {}, false,'CRM');
    console.log('GetSystemInfo - ', response);
    if (response.status === Vue.prototype.$HTTP_OK) {
      context.commit('setAppSystemInfo', response.data);
    }
  },
  async SendContactForm(context, credentials) {
    const response = await APIPOST('mam-contact', credentials, false, 'CRM');
    return response.data;
  },
};

// mutations
const mutations = {
  setAppCampaigns(state, payload) {
    state.appCampaigns = [];
    payload.forEach((item, index) => {
      let temp = {};
      if (state.appCampaigns.findIndex(partner => partner.bb_campaign_id === item.bb_campaign_id) === -1 ) {
        temp = JSON.parse(JSON.stringify(item));
        /*temp.bb_campaign_id = item.bb_campaign_id;
        temp.bb_campaign_type_id = item.bb_campaign_type_id;
        temp.campaign_code = item.campaign_code;
        temp.value = item.value;*/
        state.appCampaigns.push(temp);
      }
    });
  },
  setSellingProductGroupAppCampaigns(state, payload) {
    state.appCampaigns = [];
    state.appCampaigns = payload;
  },
  setAppPartners(state, payload) {
    state.appPartners = [];
    payload.forEach((item) => {
      let temp = {};
      if (state.appPartners.findIndex(partner => partner.crm_customer_partner_data_id === item.crm_customer_partner_data_id) === -1 ) {
        temp = JSON.parse(JSON.stringify(item));
        temp.campaign_type_code_lower = item.campaign_type_code.toString().toLowerCase();
        temp.url = item.partner_url.substring(1);
        temp.product_group_radio_button_enabled = 1;
        /*temp.crm_customer_partner_data_id = item.crm_customer_partner_data_id;
        temp.customer_selling_price_enabled = item.customer_selling_price_enabled;
        temp.imei_finder_enabled = item.imei_finder_enabled;
        temp.name = item.crm_customer_partner_data_code;*/
        state.appPartners.push(temp);
      }
    })
    if (state.appPartners.length === 1) {
      state.appSelectedPartner = JSON.parse(JSON.stringify(state.appPartners[0]));
    }
  },
  setAppStore(state, payload) {
    state.appStore = payload;
  },
  setAppSelectedPartner(state, payload) {
    state.appSelectedPartner = payload;
  },
  setAppSystemInfo(state, payload) {
    state.appSystemInfo = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
