import Vue from 'vue';

export default Vue.mixin({
  data() {
    return {
      masks: {
        accountNumber24Mask: '########-########-########',
        dateMask: '####.##.##',
        dateMaskDash: '####-##-##',
        dateTimeMaskDash: '####-##-## ##:##:##',
        imeiMask: '###############',
        phoneNumberMask: '##-###-####',
        taxNumberMaskHU: '########-#-##',
      },
    };
  },
  methods: {
    //
  },
})
