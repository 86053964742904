import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: 'outer' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ 'src/views/Home.vue')
  },
  /***
   *
   * Általános oldalak
   *
    */
  /*{
    path: '/hogyan-mukodik',
    name: 'HogyanMukodikPage',
    meta: { layout: 'outer', },
    component: () => import(/* webpackChunkName: "home" *!/ 'src/views/HowItWorksPage.vue')
  },
  {
    path: '/rolunk',
    name: 'RolunkPage',
    meta: { layout: 'outer', },
    component: () => import(/* webpackChunkName: "home" *!/ 'src/views/AboutUsPage.vue')
  },
  {
    path: '/gyik',
    name: 'GyikPage',
    meta: { layout: 'outer', },
    component: () => import(/* webpackChunkName: "home" *!/ 'src/views/FaqPage.vue')
  },
  {
    path: '/impresszum',
    name: 'ImpresszumPage',
    meta: { layout: 'outer', },
    component: () => import(/* webpackChunkName: "home" *!/ 'src/views/ImprintPage.vue')
  },
  {
    path: '/kapcsolat',
    name: 'KapcsolatPage',
    meta: { layout: 'outer', },
    component: () => import(/* webpackChunkName: "home" *!/ 'src/views/ContactPage.vue')
  },
  /***
   *
   * Speciális oldalak
   *
   */
  /*{
    path: '/:partnerName/voucher',
    name: 'partnerHome-voucher',
    meta: { layout: 'outer', bbType: 'voucher' },
    component: () => import(/* webpackChunkName: "partnerName" *!/ 'src/views/PartnerHomePage.vue')
  },
  {
    path: '/:partnerName/cashback',
    name: 'partnerHome-cashback',
    meta: { layout: 'outer', bbType: 'cashback' },
    component: () => import(/!* webpackChunkName: "partnerName" *!/ 'src/views/PartnerHomePage.vue')
  },
  {
    path: '/voucher/szemelyes-adatok',
    name: 'voucherSzemelyesAdatok',
    meta: { layout: 'outer', bbType: 'voucher' },
    component: () => import(/* webpackChunkName: "customer" *!/ 'src/views/CustomerDataPage.vue'),
  },
  {
    path: '/cashback/szemelyes-adatok',
    name: 'cashbackSzemelyesAdatok',
    meta: { layout: 'outer', bbType: 'cashback' },
    component: () => import(/* webpackChunkName: "customer" *!/ 'src/views/CustomerDataPage.vue'),
  },
  {
    path: '/pre-offer/:identicalHash',
    name: 'elozetesAjanlatElfogadasa',
    meta: { layout: 'outer' },
    component: () => import(/* webpackChunkName: "preoffer" *!/ 'src/views/PreOfferAcceptPage.vue'),
  },
  {
    path: '/elozetes-ajanlat-elutasitasa/:identicalHash',
    name: 'elozetesAjanlatElutasitasa',
    meta: { layout: 'outer' },
    component: () => import(/* webpackChunkName: "preoffer" *!/ 'src/views/PreOfferRejectPage.vue'),
  },
  {
    path: '/final-offer/:identicalHash',
    name: 'arajanlatElfogadasa',
    meta: { layout: 'outer' },
    component: () => import(/* webpackChunkName: "quote" *!/ 'src/views/FinalOfferAcceptPage.vue'),
  },
  {
    path: '/final-offer-reject/:identicalHash',
    name: 'arajanlatElutasitasa',
    meta: { layout: 'outer' },
    component: () => import(/* webpackChunkName: "quote" *!/ 'src/views/FinalOfferRejectPage.vue'),
  },
  {
    path: '/final-offer-accepted',
    name: 'arajanlatElfogadasaSikeres',
    meta: { layout: 'outer', },
    component: () => import(/* webpackChunkName: "home" *!/ 'src/views/FinalOfferAcceptSuccessPage.vue'),
  },
  {
    path: '/bejelentkezes',
    name: 'LoginPage',
    meta: { layout: 'outer' },
    component: () => import(/* webpackChunkName: "auth" *!/ 'src/views/auth/LoginPage.vue')
  },
  {
    path: '/elfelejtett-jelszo',
    name: 'ForgotMyPasswordPage',
    meta: { layout: 'outer' },
    component: () => import(/* webpackChunkName: "auth" *!/ 'src/views/auth/ForgotMyPasswordPage.vue')
  },
  {
    path: '/iranyitopult',
    name: 'MyDashboard',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "admin" *!/ 'src/views/inner/MyDashboardPage.vue')
  },*/
  {
    path: '*',
    meta: { layout: 'outer' },
    component: () => import(/* webpackChunkName: "nopage" */ 'src/views/NoPage.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, offset: { x: 0, y: 64 } };
    }
    return { x: 0, y: 0 };
  }
})

export default router
